<swe-card header="{{languageService.getItem(51)}}" [(open)]="open" (refreshChange)="search()" (openChange)="search()" [rulelist]="['SweUserMarkedDates']" icon="bi-calendar-check">
  <span swe-rightcommand><i class="bi bi-swe-fw bi-plus-lg bi-swe-pull-right swe-click" (click)="goto(0,true,$event)" *ngIf="userdata.UserMarkedDateAccess>1"></i></span>
  <div class="py-2 row">
    <div class="col-xl-5 col-lg-6">
      <swe-calendar [(model)]="current" (modelChange)="manageDate()" [items]="usermarkeddates" [dottedDays]="bookedDays" [hasWeeknumbers]="true" [disabled]="loading||userdata.UserMarkedDateAccess<2" (chosenChange)="manageChosen($event)" [showDots]="showBookings"></swe-calendar>
    </div>
      <div class="d-inline-block border offset-xl-1 col-lg-6 border-secondary p-2 col-xl-3 align-top" style="min-width:250px;" *ngIf="permissionService.permissions.UserMarkedDateShowPartOfDay||userdata.UserMarkedDateComment>0" >
      <ng-container *ngIf="permissionService.permissions.UserMarkedDateShowPartOfDay">
        <label>{{languageService.getItem(1069)}}</label>
        <div class="my-3">
          <swe-datetime [(start)]="starttime" [(end)]="endtime" [hideDate]="true" [disabled]="wholeday"></swe-datetime>
        </div>
        <swe-element [type]="'System.Boolean'" [(model)]="wholeday" (modelChange)="toggleTime()" [label]="languageService.getItem(1070)" [statusLabel]="4" [customCols]="0" [labelToRight]="true"></swe-element>
        <swe-element [type]="'System.Boolean'" [(model)]="showBookings" (modelChange)="toggleTime()" [label]="languageService.getItem(1259)" [statusLabel]="4" [customCols]="0" [labelToRight]="true" *ngIf="!permissionService.permissions.HideShifts&&userdata.BookingAccess>0"></swe-element>

      </ng-container>
      <swe-element [type]="commenttype" [items]="commentitems" [(model)]="comment" [label]="languageService.getItem(399)" [statusLabel]="2" *ngIf="userdata.UserMarkedDateComment>0"></swe-element>
    </div>
  </div>
  <div class="row py-2">
    <div class="col-12">
      <div class="btn-group me-1">
        <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe btn-swe-primary" [ngClass]="{'active': listutility.allchecked}" (click)="listutility.checkall(!listutility.allchecked)" *ngIf="usermarkeddates.length>0">
          <span><i class="bi bi-check-lg"></i></span>
        </button>
        <swe-confirm [body]="languageService.getItem(194)" (acceptChange)="deleteAll($event)">
          <button [attr.aria-label]="languageService.getItem(17)" class="btn btn-swe" *ngIf="listutility.ischecked">
            <span><i class="bi bi-swe-fw bi-trash-fill"></i></span>
          </button>
        </swe-confirm>
      </div>
      <swe-list [data]="usermarkeddates" [loading]="loading">
        <div *ngFor="let obj of concatArr">
          <a *ngIf="showBookings&&obj.tr&&permissionService.permissions.Timereports==0&&bookings.length>0" class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'swe-disabled': obj.Access<1, 'list-group-item-warning': obj.Id==settingService.lasttimereport}" (click)="(obj.Access>0)&&goto(obj.Id, obj.umd, $event)">
            <div class="col-3 col-md-1">
              <i class="bi bi-swe-border swe-icon-color" *ngIf="obj.Status>10" [attr.title]="obj.StatusText" [ngClass]="{'bi-arrow-up-right-square': obj.Status==20,'bi-key-fill': obj.Status==30,'bi-clock-fill': obj.Status==40,'bi-check-lg': obj.Status==50,'bi-lock-fill': obj.Status==60,'alert-danger border-danger': obj.Amount==0&&obj.Max>0,'alert-warning border-warning': (obj.Amount>0&&obj.Amount<obj.Max), 'alert-success border-success': (obj.Amount==obj.Max)}"></i>
            </div>
            <div class="col-9 col-md-11 d-flex flex-wrap">
              <div class="col-12 col-md-3">{{obj.dateheader}}</div>
              <div class="col-12 col-md-3">
                <span>{{obj.Header}}</span>
              </div>
              <div class="col-12 col-md-3">
                <span>{{obj.TimeType}}</span>
              </div>
              <div class="col-12 col-md-3">
                <span>{{obj.Level}}</span>
              </div>
            </div>
          </a>
          <a *ngIf="showBookings&&obj.tr&&permissionService.permissions.Timereports>0" class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'swe-disabled': obj.Access<1, 'list-group-item-warning': obj.Id==settingService.lasttimereport}" (click)="(obj.Access>0)&&goto(obj.Id, obj.umd, $event)">
            <div class="col-3 col-md-1">
              <i class="bi bi-swe-border swe-icon-color" [attr.title]="getStatusText(obj.Status)" [ngClass]="{ 'swe-grayed-out': obj.Status < 40,'bi-clock-fill': obj.Status<=40,'bi-check-lg': obj.Status==50,'bi-lock-fill': obj.Status==60}"></i>
              <i class="bi bi-swe-border bi-person-bounding-box" *ngIf="obj.IsStandby" [attr.title]="languageService.getItem(382)"></i>
              <i class="bi bi-swe-border bi-person-x-fill" *ngIf="obj.IsAbscent" [attr.title]="languageService.getItem(280)"></i>
            </div>
            <div class="col-9 col-md-11 d-flex flex-wrap">
              <div class="col-12 col-md-3">{{obj.dateheader}}</div>
              <div class="col-12 col-md-3">
                <span>{{obj.Header}}</span>
              </div>
              <div class="col-12 col-md-3">
                <span>{{obj.TimeType}}</span>
              </div>
              <div class="col-12 col-md-3">
                <span>{{obj.Level}}</span>
              </div>
            </div>
          </a>
          <div *ngIf="obj.umd">
            <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-info': obj.checked, 'swe-disabled': (userdata.UserMarkedDateAccess<2)&&(obj.Access<1)}" (click)="(obj.Access>0)&&goto(obj.Id, obj.umd, $event)">
              <div class="col-3 col-md-1" (click)="listutility.toggle(obj, $event)">
                <span class="bi-swe-stack bi-swe-letter-circle-small" *ngIf="obj.checked">
                  <i class="bi bi-check-circle-fill bi-swe-stack-2x"></i>
                </span>
                <img class="swe-circle-image" alt="{{obj.Name}}" sweImage imageFolder="userimages" [imageFile]="obj.Image" imageWidth="32" imageHeight="32" *ngIf="!obj.checked&&obj.Image.length>0" />
                <span class="bi-swe-stack bi-swe-letter-circle-small" *ngIf="!obj.checked&&obj.Image.length==0">
                  <i class="bi bi-circle-fill bi-swe-stack-2x"></i>
                  <span class="bi-swe-stack-1x bi-swe-stack-1x bi-swe-letter">{{obj.Name[0]}}</span>
                </span>
              </div>
              <div class="col-9 col-md-11 d-flex flex-wrap">
                <div class="col-12 col-md-3">{{obj.dateheader}}</div>
                <div class="col-12 col-md-6">{{obj.Comment}}</div>
                <div class="col-12 col-md-3"><div style="display:inline-block;width:10px;height:10px;border:1px solid #999999;" [ngStyle]="{'background-color': obj.Color}"></div>&nbsp;{{obj.Type}}</div>
              </div>
            </a>
          </div>
        </div>
      </swe-list>

    </div>
  </div>
</swe-card>
