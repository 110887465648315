import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { GeneralService } from '../../_services/general.service';
import { AlertService } from '../../_services/alert.service';
import { DataService } from '../../_services/data.service';
import { ListService } from '../../_services/list.service';
import { DateTimeService } from '../../_services/datetime.service';
import { PermissionService } from '../../_services/permission.service';

@Component({
  selector: 'swe-usercontract',
  templateUrl: './usercontract.component.html'
})
export class UserContractComponent implements OnInit, OnChanges {
  @Input() id: number;
  @Input() open: boolean;
  @Input() userdata: any;

  private _currentId: number = 0;
  private _current: any = {};
  private _usercontracts: any[] = [];
  private _contracts: any[] = [];
  private _weekdays: any[] = [];
  private _hours: any[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private alertService: AlertService,
    private generalService: GeneralService,
    private listService: ListService,
    private dateTimeService: DateTimeService
  ) {

  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges($event) {
    if($event.id)
    {
      this.load();
    }
  }



  /*Properties*/
  public get cards() {
    return {
      timebank: false,
      las: false,
      employment: false
    };
  }
  public get currentId() {
    return this._currentId;
  }
  public set currentId(val) {
    this._currentId = val;
  }
  public get current() {
    return this._current;
  }
  public get usercontracts() {
    return this._usercontracts;
  }
  public get contracts() {
    return this._contracts;
  }
  public get weekdays() {
    return this._weekdays;
  }
  public get hours() {
    return this._hours;
  }


  //Methods
  public reload() {
    this.load();
  }
  public manageContracts() {
    let usercontract = this.listService.find(this._usercontracts, 'Id', this._currentId);
    if (usercontract) {
      this._current = usercontract;
    }
  }
  public save() {

    if (!this.validatedatetime()) { return; }
    if (!this.validatecontract()) { return; }

    let verb = 'POST';
    if (this._current.Id > 0) {
      verb = 'PUT';
    }

    this.dataService.tokenRequest('/api/v1/users/' + this.id + '/contracts', verb, this._current, 'text', 'response')
      .subscribe((response) => {

        this.alertService.Add({ type: 'success', message: response.body });
        if (response.status == 201) {
          this._currentId = parseInt(response.headers.get('Location'));
          this.reload();
        }

      });
  }
  public delete() {

    this.dataService.tokenRequest('/api/v1/users/' + this.id + '/contracts/' + this._current.Id, 'DELETE', {}, 'text')
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res });

        this._currentId = 0;
        this.reload();
      });
  }



  //Functions
  private init() {

    this._contracts = [];
    this.generalService.contracts.forEach((contract) => {

      this._contracts.push({ Id: contract.Key, Name: contract.Value });

    });

    this._weekdays = [
      { Id: -1, Name: "-" },
      { Id: 0, Name: this.languageService.getItem(209) },
      { Id: 1, Name: this.languageService.getItem(210) },
      { Id: 2, Name: this.languageService.getItem(211) },
      { Id: 3, Name: this.languageService.getItem(212) },
      { Id: 4, Name: this.languageService.getItem(213) },
      { Id: 5, Name: this.languageService.getItem(214) },
      { Id: 6, Name: this.languageService.getItem(215) }
    ];

    this._hours = [
      { Id: -1, Name: "--:--" },
    ];
    for (let i = 0; i < 24; i++) {
      if (i < 10) {
        this._hours.push({ Id: i, Name: "0" + i + ":00" });
      }
      else {
        this._hours.push({ Id: i, Name: i + ":00" });
      }
    }

  }
  private load() {

    //Get UserContracts
    this._currentId = 0;
    this._current = {
      Id: 0,
      Name: this.languageService.getItem(1190),
      UserId: this.id,
      Start: this.dateTimeService.format(new Date(), 'yyyy-MM-dd'),
      End: this.dateTimeService.format(new Date(2100, 0, 1), 'yyyy-MM-dd'),
      BreakpointWeekday: -1,
      BreakpointHour: -1
    };
    this._usercontracts = [this._current];
    this.dataService.tokenRequest('/api/v1/users/' + this.id + '/contracts', 'GET', {})
      .subscribe((res) => {
        if (res) {
          let counter = 0;
          res.forEach((item) => {
            let contract = this.listService.find(this._contracts, 'Id', item.ContractId);
            if (contract) {
              item.Name = contract.Name;
            }

            if (counter == 0 && this._currentId <= 0) {
              this._currentId = item.Id;
            }

            this._usercontracts.push(item);
            counter++;
          });
          this._usercontracts.sort((a, b) => (a == this._current || b == this._current) || (!a.Name || !b.Name || (a.Name.toLowerCase() > b.Name.toLowerCase())) ? 1 : -1);
          this._current = this._usercontracts.find(x => x.Id == this._currentId);
        }
      });

  }
  private validatedatetime() {
    let start = new Date(this._current.Start);
    let end = new Date(this._current.End);

    /*Test*/
    return this.dateTimeService.validation(start, end); 
  }
  private validatecontract() {
    if (this._current.ContractId > 0) {
      return true;
    }

    this.alertService.Add({ type: 'danger', message: this.languageService.getItem(1215) });
    return false;
  }

}
