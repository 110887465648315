import { Component, Input, EventEmitter, Output, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { LanguageService } from '../_services/language.service';
import { PermissionService } from '../_services/permission.service';
import { DataService } from '../_services/data.service';
import { SettingService } from '../_services/setting.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'swe-levelsearch',
  templateUrl: './levelsearch.component.html',
})
export class LevelSearchComponent implements OnInit, OnChanges, OnDestroy {
  @Input() markChanges: boolean;
  @Input() changed: boolean;
  @Input() label: string;
  @Input() statusLabel: number = 0; //0 = Hide, 1 = Standard, 2 = Grouped, 3 = Inline
  @Input() displayname: string = ''; 
  @Input() model: any;
  @Output() modelChange = new EventEmitter<any>();
  @Input() disabled: boolean = false;
  @Input() roleRequired: boolean = false;
  @Input() roleId: number;
  @Input() chosen: any[] = [];
  @Input() onlyone: boolean = false;
  @Input() type: string;
  @Input() autoload: boolean = false;
  @Input() reset: boolean = false;
  @Input() bottomMargin: number = 0;
  @Input() optional: boolean = false;
  @Input() keepOpen: boolean = false;
  @Input() storeAtClient: boolean = false;
  @Input() loadOnClick: boolean = false;

  private unsubsribe$ = new Subject<void>();
  private _current: number;
  private _more: boolean;
  private _all: boolean;
  private _nohits: boolean;
  private _warning: string = '';
  private _active: boolean;
  private _multiple: number = 1;
  private _isSearching: boolean;
  private _searchresult: any[] = [];
  private _level: string = '';
  private _lastlevel: string = '';
  private _original: number;
  private _isPicking: boolean = false;
  private _initialSearch: boolean = false;
  private _levelSearch: string = '';
  //Timeouts
  private _lostfocustimeout: any;
  private _searchtimeout: any;

  constructor(
    public languageService: LanguageService,
    private permissionService: PermissionService,
    private settingService: SettingService,
    private dataService: DataService) {

    settingService.onRefresh$
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe(() => {
        this._isSearching = false;
      });

  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
  }

  ngOnInit() {
    
    if (this.reset) {
      this.resetLevel();
    }
    if (this.autoload) {
      this.preload();
    }

    if (this.onlyone && this.model) {
      this._original = this.model;
    }
  }

  ngOnChanges() {
    if (this.displayname && this.displayname.length > 0 && (!this._level || this._level.length == 0)) {
      this._level = this._lastlevel = this.displayname;
    }
    if (this.reset) {
      this.resetLevel();
    }
    if (this.autoload) {
      this.preload();
    }
  }


  /*Properties*/
  public get cols(): number {

    if (this.statusLabel == 1) {
      //Standard
      return this.permissionService.permissions.ProfileLabel;
    }
    else if (this.statusLabel == 2) {
      //Grouped
      return 0;
    }
    else if (this.statusLabel == 3) {
      //Inline
      return 3;
    }

    return 0;
  }
  public get current() {
    return this._current;
  }
  public get more() {
    return this._more;
  }
  public get all() {
    return this._all;
  }
  public get nohits() {
    return this._nohits;
  }
  public get warning() {
    return this._warning;
  }
  public get active() {
    return this._active;
  }
  public get level() {
    return this._level;
  }
  public set level(value:string) {
    this._level = value;
    this._levelSearch = value;
  }
  public get isSearching() {
    return this._isSearching;
  }
  public get searchresult() {
    return this._searchresult;
  }
  public get isChanged() {
    return this.changed;
  }



  /*Methods*/
  public isChosen(id: number) {
    return this.chosen.map(item => item.Id).indexOf(id) > -1;
  }
  public getLabelClass() {
    if (this.cols == 0) {
      return "";
    }
    return 'col-form-label col-' + this.cols;
  }

  public getInputClass() {
    if (this.cols == 0) {
      return "";
    }
    return 'col-' + (12 - this.cols);
  }
  public search(getmore: number) {
    this._warning = '';

    if (this._isPicking) {
      return;
    }

    if (this.roleRequired && this.roleId <= 0 && this._level.length > 0) {
      this._warning = this.languageService.getItem(849);
      return;
    }


    //If you already have a timout, clear it
    if (this._lostfocustimeout) { clearTimeout(this._lostfocustimeout); }

    //If you already have a timout, clear it
    if (this._searchtimeout) { clearTimeout(this._searchtimeout); }

    
    //Start new timeout
    this._searchtimeout = setTimeout(() => {

      this._active = true;
      if (this._levelSearch.length == 0) {
        //self.searching = false;
        this.resetLevel();
        return;
      }

      let top = 5;
      if (getmore == 2) {
        //All
        top = 0;
        this._searchresult = [];
      }
      else if (getmore == 1) {
        //More
        this._multiple++;
      }
      else {
        this._multiple = 1;
        this._searchresult = [];
      }

      this._isSearching = true;

      let filter = {
        Name: this._levelSearch,
        Top: top,
        Multiple: this._multiple,
        Type: this.type,
        IncludeParents: !(this.onlyone),
        RoleId: this.roleId
      };

      this.dataService.tokenRequest('/api/v1/levels/search', 'POST', filter)
        .subscribe(res => {

          this._all = (res.More && this._searchresult.length > 0);

          res.Levels.forEach((item) => {
            this._searchresult.push(item);
          });

          this._nohits = (res.Levels.length == 0);


          this._more = res.More;
        });

      this._initialSearch = false;
    }
      , 500);

  }
  public searchOnClick() {
    this._initialSearch = true;
    this._levelSearch = '*';
    this.search(0);
  }

  public resetLevel() {
    this._lastlevel = '';
    this._level = '';
    this.displayname = '';
    this.model = 0;
    this._isSearching = false;
    this._active = false;

    this.get({Id: 0, Name: '', Path: ''}, 0, false);
  }
  public lostfocus() {
    //If you already have a timout, clear it
    if (this._lostfocustimeout) { clearTimeout(this._lostfocustimeout); }

    //Start new timeout
    this._lostfocustimeout = setTimeout(() => {
      this._level = (this.onlyone) ? this._lastlevel : '';
      this._isSearching = false;
      this._active = false;
      if (this._level.length == 0) {
        this._warning = '';
      }

    }, 500);
  }
  public get(item, index,  exitsearch) {

    if (!item) { return; }

    if (!this.optional && item.Id == 0) { return; }

    if (!item.OnlyParent) {
      this._current = index;
      this._lastlevel = item.Name;

      if (this.onlyone) {
        //Use IsPicking to avoid a click in list will update level displayname and a new seach is done
        this._isPicking = true;
        this._level = item.Name;
        this._isPicking = false;
        this._isSearching = this.keepOpen;
        this._active = false;

        this.model = item.Id;
        this.modelChange.emit(this.model);

        if (item.Id > 0 && this.storeAtClient) {
          localStorage.setItem('level', `${item.Id}|${item.Name}|${item.Path}|${index}`);
        }
      }
      else {
        this.modelChange.emit({ item: item, exit: exitsearch });
      }
    }
    else {

      let filter = {
        ParentId: item.Id,
        Type: this.type
      };

      this.dataService.tokenRequest('/api/v1/levels/search', 'POST', filter)
        .subscribe(res => {

          res.Levels.forEach((child) => {
            this.modelChange.emit({ item: child, exit: false });
          });

        });
    }

    if (this.onlyone && this.markChanges) {
      this.changed = (typeof this._original != 'undefined' && this._original != this.model);
    }

    if (exitsearch) {
      this._isSearching = false;
    }

    //If you already have a timout, clear it
    if (this._lostfocustimeout) { clearTimeout(this._lostfocustimeout); }

  }
  public keyup(e: KeyboardEvent) {

    let currentRow = this._current;

    if (e.keyCode == 40) {
      //Arrow key down
      if (currentRow < this._searchresult.length - 1) {
        currentRow++;
      }
      else if (this._all && currentRow == this._searchresult.length) {
        currentRow++;
      }
      else if (this._more && currentRow == this._searchresult.length - 1) {
        currentRow++;
      }
      else {
        currentRow = 0;
      }
    }
    else if (e.keyCode == 38) {
      //Arrow key up
      if (currentRow > 0) {
        currentRow--;
      }
      else if (this._all) {
        currentRow = this._searchresult.length + 1;
      }
      else if (this._more) {
        currentRow = this._searchresult.length
      }
      else {
        currentRow = this._searchresult.length - 1;
      }
    }
    else if (e.keyCode == 13) {
      //Enter
      if (this._all && currentRow == this._searchresult.length + 1) {
        this.search(2);
      }
      else if (this._more && currentRow == this._searchresult.length) {
        this.search(1);
      }
      else if (!this._nohits) {
        this.get(this._searchresult[currentRow], currentRow, !e.ctrlKey);
      }
    }

    this._current = currentRow;
  };
  public keydown(e: KeyboardEvent) {

    let currentRow = this._current;

    if (e.keyCode == 9 && this._level.length > 0) {
      //Tab
      this.get(this._searchresult[currentRow], currentRow, !e.ctrlKey);
    }
  };


  private preload() {
    //To be sure original get is finished
    setTimeout(() => {
      let levelObj = localStorage.getItem('level');
      if (typeof levelObj == "undefined" || levelObj == null) {
        levelObj = this.permissionService.permissions.BookingLevel;
      }
      if (typeof levelObj != "undefined" && levelObj != null) {
        let levelParts = levelObj.split('|');

        this.get({
          Id: levelParts[0],
          Name: levelParts[1],
          Path: levelParts[2],
          OnlyParent: false
        }, levelParts[3], true);
      }
      else {
        let filter = {
          Name: this.level,
          Top: 5,
          Multiple: 1,
          Type: this.type
        }

        this.dataService.tokenRequest('/api/v1/levels/search', 'POST', filter)
          .subscribe(res => {

            if (res.Levels.length > 0) {
              this.get(res.Levels[0], 0, true);
            }

          });
      }
    }, 500);
  }

}
