import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

import { PermissionService } from '../_services/permission.service';
import { LanguageService } from '../_services/language.service';
import { DateTimeService } from '../_services/datetime.service';

@Component({
  selector: 'swe-breaklist',
  templateUrl: './breaklist.component.html',
})
export class BreakListComponent implements OnInit {
  @Input() markChanges: boolean;
  @Input() model: any;
  @Output() modelChange = new EventEmitter<any>();
  @Input() modelStart: any;
  @Input() disabled: boolean;
  @Input() disabledStart: boolean;
  @Input() minStart: any;
  @Input() maxStart: any;
  @Input() reset: boolean;
  @Input() hasBreakInfo: boolean;
  @Input() onlyOne: boolean = false;
  @Input() readonly: boolean = false;

  private _break: any;
  private _breakStart: any;
  private _isRedo: boolean = false;

  constructor(
    public permissionService: PermissionService,
    public languageService: LanguageService
  ) {

  }

  ngOnInit() {

    if (Array.isArray(this.model) && this.model.length > 0) {
      let array: any[] = this.model;
      this._break = array[array.length - 1].Break;
      this._breakStart = array[array.length - 1].BreakStart;
    }
  }


  //Properties
  public get isRedo() {
    return this._isRedo;
  }


  //Methods
  public add() {

    let item = { Break: this._break, BreakStart: this._breakStart };

    if (Array.isArray(this.model)) {
      this.model.push(item);
    }
  }
  public remove(obj) {
    if (Array.isArray(this.model)) {
      const index = this.model.indexOf(obj);
      if (index > -1) {
        this.model.splice(index, 1);
      }
    }
  }
  public redo() {

    this._isRedo = true;

    this.modelChange.emit(this.model);

    setTimeout(() => {
      this._isRedo = false;
    }, 1000);
  }
}
