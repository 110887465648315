import { Component, OnInit, Input, OnChanges, ViewChild, ElementRef } from '@angular/core';

import { ChartDataset, ChartOptions, ChartType } from 'chart.js';

import { LanguageService } from '../_services/language.service';
import { PermissionService } from '../_services/permission.service';
import { DataService } from '../_services/data.service';
import { SettingService } from '../_services/setting.service';
import { DateTimeService } from '../_services/datetime.service';
import { ListService } from '../_services/list.service';
import { GeneralService } from '../_services/general.service';
import * as Chart from 'chart.js';





@Component({
  selector: 'swe-shiftschart',
  templateUrl: './shiftschart.component.html'
})
export class ShiftsChartComponent implements OnInit, OnChanges {

  @Input() showgroupby: boolean = true; 
  @Input() start: Date = null;
  @Input() end: Date = null;
  @Input() showhour: boolean = false;
  @Input() precision: number = 0; //Auto
  @Input() show: number = 3; //Shifts And Availability
  @Input() rows: ShiftChartRow[] = [];
  @Input() comparedata: ShiftChartCompareData[] = [];
  @ViewChild('sweChartElement') chartElement: ElementRef;

  private _loading: boolean = false;
  private _slots: ShiftChartSlot[] = [];
  private _intervaldaystarttime: number = 0;
  private _intervaldayendtime: number = 0;
  //Sums
  private _sumoptions: number = 1;
  private _filledsum: number[] = [];
  private _notfilledsum: number[] = [];
  private _availabilitysum: number[] = [];
  private _sum: number = 0;
  private _comparesum: number = 0;
  private _diff: number = 0;
  //Chart
  private _charttype: ChartType = 'bar';
  private _chartdata: ChartDataset[] = [];
  private _chartlabels: string[] = [];
  private _chartoptions: ChartOptions = {};
  private _chartlegend: boolean = true;
  
  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    public dateTimeService: DateTimeService,
    private generalService: GeneralService,
    private dataService: DataService,
    private listService: ListService
  ) {
    
  }


  ngOnInit() {
    
  }
  ngOnChanges() {
    this.init();

    this.manageSlots();

    this.manageSums();

    this.manageChart();
  }

  


  //Properties
  public get isloading() {
    return this._loading;
  }
  public get sumoptions() {
    return this._sumoptions;
  }
  public set sumoptions(val) {
    this._sumoptions = val;
  }
  public get sum() {
    return this._sum;
  }
  public get comparesum() {
    return this._comparesum;
  }
  public get diff() {
    return this._diff;
  }
  public get charttype() {
    return this._charttype;
  }
  public get chartdata() {
    return this._chartdata;
  }
  public get chartlabels() {
    return this._chartlabels;
  }
  public get chartoptions() {
    return this._chartoptions;
  }
  public get chartlegend() {
    return this._chartlegend;
  }
  public get chartplugins() {
    return [{
      beforeDraw(chart) {
        const ctx = chart.ctx;
        const canvas = chart.canvas;
        
        //ctx.save();
        ctx.fillStyle = '#FFFFFF';

        ctx.fillRect(0, 0, canvas.clientWidth, canvas.clientHeight);
        //ctx.restore();
      }
    }];
  }
  


  //Methods
  public load() {
    
    this._loading = true;

    this._loading = false;
  }
  public changeSumOptions() {

    this.manageSums();

    this.manageChart();
  }
  public download() {

    let element = this.chartElement.nativeElement;
    if (element) {

      var link = document.createElement('a');
      link.download = 'SwedeTimeChart.png';
      link.href = element.toDataURL();
      link.click();

    }
   
  }



  //Functions
  private init() {
    this._sumoptions = this.permissionService.permissions.ChartObjectDefault ? 1 : 0;
    this._intervaldaystarttime = this.permissionService.permissions.CalendarDayStart;
    this._intervaldayendtime = this.permissionService.permissions.CalendarDayEnd;
  }
  private manageSlots() {

    this._slots = [];

    if (this.showhour) {
      //Hours
      let s = this.dateTimeService.addHours(this.dateTimeService.toDate(this.start), this._intervaldaystarttime);
      let e = this.dateTimeService.addHours(this.dateTimeService.toDate(this.start), this._intervaldayendtime);
      while (s < e) {

        let label = '';
        if (s.getMinutes() == 0) {
          if (s.getHours() < 10) {
            label = '0';
          }
          label += s.getHours();
        }

        this._slots.push({
          start: s,
          label: label
        });

        let minutes = 60;
        if (this.precision == -3) { /*Half hour*/ minutes = 30; }
        else if (this.precision == -4) { /*Quarter*/ minutes = 15; }

        s = this.dateTimeService.addMinutes(s, minutes);
      }
    }
    else {
      //Date
      let s = this.dateTimeService.toDate(this.start);
      let e = this.dateTimeService.toDate(this.end);

      let diff = e.getTime() - s.getTime();
      let isyear = diff >= (this.dateTimeService.oneday * 365);
      let ishour = diff <= (this.dateTimeService.oneday * this.permissionService.permissions.HourPrecision);

      let minutes = 1440;
      if (this.precision == 0 && ishour) { /*Auto*/ minutes = 60; }
      else if (this.precision == -2) { /*Hour*/ minutes = 60; }
      else if (this.precision == -3) { /*Half hour*/ minutes = 30; }
      else if (this.precision == -4) { /*Quarter*/ minutes = 15; }

      while (s < this.end) {

        let label = s.getDate() + ' ' + this.dateTimeService.monthname(s).substring(0, 3);

        this._slots.push({
          start: s,
          label: label
        });

        if ((isyear && this.precision == 0) || this.precision == 2) {
          //Month
          s = this.dateTimeService.addDays(s, 1);
          while (s.getDate() > 1) {
            s = this.dateTimeService.addDays(s, 1);
          }
        }
        else if (this.precision == 1) {
          //Week
          s = this.dateTimeService.addDays(s, 1);
          while (s.getDay() != 1) {
            s = this.dateTimeService.addDays(s, 1);
          }
        }
        else {
          let old = s;
          s = this.dateTimeService.addMinutes(s, minutes);
          if (old.getTimezoneOffset() < s.getTimezoneOffset()) {
            //Offset adjust
            s = this.dateTimeService.addMinutes(s, s.getTimezoneOffset() - old.getTimezoneOffset());
          }
        }
      }
    }

  }
  private manageChart() {
    let red = '#f8d7da';
    let green = '#cce6cc';
    let blue = '#d1ecf1';

    let redBorder = '#dc3545';
    let greenBorder = '#008000';
    let blueBorder = '#17a2b8';

    let redLine = '#dc3545';
    let greenLine = '#198754';
    let blackLine = '#000000';
    let blueLine = '#0d6efd';
    let orangeLine = '#ff9f40';

    this._chartdata = [];

    if (this._sumoptions != 2) {

      if ((this.show & 2) == 2) {
        this._chartdata.push({ data: this._availabilitysum, label: this.languageService.getItem(679), type: 'line', borderWidth: 2, pointBackgroundColor: 'rgba(255, 255, 255, 0)', pointBorderColor: blueBorder, backgroundColor: 'rgba(255, 255, 255, 0)', hoverBackgroundColor: blue, borderColor: blueBorder, order: 100 });
      }

      this._chartdata.push({ data: this._filledsum, label: this.languageService.getItem(677), type: 'bar', borderWidth: 1, backgroundColor: green, hoverBackgroundColor: green, borderColor: greenBorder, stack: 'Stack 0', order: 100 });
      this._chartdata.push({ data: this._notfilledsum, label: this.languageService.getItem(678), type: 'bar', borderWidth: 1, backgroundColor: red, hoverBackgroundColor: red, borderColor: redBorder, stack: 'Stack 0', order: 100 });

      this._chartlegend = true;
    }
    else {

      let groupcounter = 0;
      this.rows.forEach((row) => {

        if ((this.show & 2) == 2) {
          this._chartdata.push({ data: row.availabilitysum, label: row.name, type: 'bar', borderWidth: 1, backgroundColor: blue, hoverBackgroundColor: blue, borderColor: blueBorder, order: 100 });
        }

        if (row.id == 0) {
          this._chartdata.push({ data: row.notfilledsum, label: row.name, type: 'bar', borderWidth: 1, backgroundColor: red, hoverBackgroundColor: red, borderColor: redBorder, stack: 'Stack ' + groupcounter, order: 100 });
        }
        else {
          this._chartdata.push({ data: row.filledsum, label: row.name, type: 'bar', borderWidth: 1, backgroundColor: green, hoverBackgroundColor: green, borderColor: greenBorder, stack: 'Stack ' + groupcounter, order: 100 });
        }

        groupcounter++;
      });

      this._chartlegend = false;
    }

    //Sum
    let tmpsum = 0;
    this._filledsum.forEach((val) => {
      tmpsum += val;
    });
    this._sum = tmpsum;

    //Compare
    this._comparesum = 0;
    this.comparedata.forEach((compare) => {
      let color = blackLine;
      if (compare.color == 'red') { color = redLine; }
      if (compare.color == 'blue') { color = blueLine; }
      if (compare.color == 'green') { color = greenLine; }
      if (compare.color == 'orange') { color = orangeLine; }

      let dash = [];
      if (compare.dash) { dash = [5, 5]; }

      if (compare.sum) {
        let tmpcomparesum = 0;
        compare.data.forEach((val) => {
          tmpcomparesum += val;
        });
        this._comparesum += tmpcomparesum;
      }

      this._chartdata.push({ data: compare.data, label: compare.name, type: compare.charttype, borderWidth: 2, pointBackgroundColor: 'rgba(255, 255, 255, 0)', pointBorderColor: color, backgroundColor: 'rgba(255, 255, 255, 0)', hoverBackgroundColor: color, borderColor: color, borderDash: dash });
    });

    //Diff
    this._diff = 0;
    if (this._comparesum > 0) {
      this._diff = this.generalService.formatdecimal((this._sum / this._comparesum) * 100);
    }

    //Format
    this._sum = this.generalService.formatdecimal(this._sum);
    this._comparesum = this.generalService.formatdecimal(this._comparesum);

    //Labels
    this._chartlabels = this._slots.map((slot) => { return slot.label; });

    //Is stacked
    let stacked = typeof this.permissionService.permissions.ChartStacked == 'undefined' ? true : this.permissionService.permissions.ChartStacked;

    let title = this.languageService.getItem(1286) + ': ' + this._sum + ', ';
    title += this.languageService.getItem(1287) + ': ' + this._comparesum + ', ';
    title += this.languageService.getItem(1288) + ': ' + this._diff + '%';

    //Options
    this._chartoptions = {
      responsive: true,
      elements: {
        line: {
          cubicInterpolationMode: 'monotone',
          tension: 0.5
        }
      },
      plugins: {
        title: {
          display: true,
          text: title,
          position: 'top',
          align: 'end'
        },
        tooltip: {
          mode: 'index',
          position: 'nearest'
        }
      },
      aspectRatio: 6,
      maintainAspectRatio: true
      
    };
  }

  private manageSums() {

    let emptyarray: number[] = [];
    for (var i = 0; i < this._slots.length; i++) {
      emptyarray.push(0);

    }

    //Reset
    this._filledsum = [...emptyarray];
    this._notfilledsum = [...emptyarray];
    this._availabilitysum = [...emptyarray];

    


    this.rows.forEach((row) => {

      //Reset
      let bits = 0;
      let availablesum = [...emptyarray];
      row.filledsum = [...emptyarray];
      row.notfilledsum = [...emptyarray];
      row.availabilitysum = [...emptyarray];

      //Availabilities
      row.availabilities.forEach((availability) => {

        //Interval Sum
        this.intervalAvailableSum(row, availability, availablesum);
      });

      //Shifts
      row.shifts.forEach((shift) => {

        //Interval Sums
        if (!shift.MinusTime) {
          if (row.id != 0) {
            bits = this.intervalSum(row, shift, availablesum, bits);
          }
          else {
            this.intervalNotFilledSum(row, shift);
          }
        }

        //Activities
        shift.Activities.forEach((activity) => {

          if (row.id != 0) {
            this.intervalSumActivity(row, activity, availablesum);
          }
          else {
            this.intervalNotFilledSumActivity(row, activity, (shift.Max - shift.Amount));
          }

          });
      });

    });
  }
  private intervalAvailableSum(row: any, availabilty: any, availablesum: any[]) {
    if (availabilty.Value <= 0) {
      return;
    }

    let bStart = new Date(availabilty.Start).getTime();
    let bEnd = new Date(availabilty.End).getTime();

    let factor = availabilty.Factor;

    let diff = (this._slots.length > 1) ? (this._slots[1].start.getTime() - this._slots[0].start.getTime()) : (new Date(this.settingService.end('booking')).getTime() - this._slots[0].start.getTime());
    for (let i = 0; i < this._slots.length; i++) {

      let iStart = this._slots[i].start.getTime();
      let iEnd = iStart + diff;
      if (this.precision > 0) {
        //Week or Month
        if ((i + 1) < this._slots.length) {
          iEnd = this._slots[i + 1].start.getTime();
        }
        else {
          iEnd = bEnd;
        }
      }

      if ((iStart <= bStart && bStart < iEnd) //Starts in interval
        ||
        (iStart < bEnd && bEnd <= iEnd) //Ends in interval 
        ||
        (iStart > bStart && bEnd > iEnd) //Spans over whole interval
      ) {

        let calcStart = bStart < iStart ? iStart : bStart;
        let calcEnd = bEnd > iEnd ? iEnd : bEnd;

        let calc = factor * (calcEnd - calcStart) / (60 * 60 * 1000);
        if (this._sumoptions >= 1) {
          calc = 1;
        }

        this._availabilitysum[i] += calc;
        row.availabilitysum[i] += calc;
        availablesum[i] = calc;

      }

    }
  }
  private intervalSum(row: any, shift: any, availablesum: any[], bits: number): number {
    let start = new Date(shift.Start);
    let bStart = start.getTime();
    let end = new Date(shift.End);
    let bEnd = end.getTime();
    let bBreak = shift.Break * 60 * 1000;
    let bBreakStart = new Date(shift.BreakStart).getTime();

    let procentTime = 1 - (bBreak / (bEnd - bStart));
    if (bEnd - bStart == 0) {
      procentTime = 1;
    }
    
    //Calendar Day Time----------------
    let calendarDayTime = 0;
    if (shift.EmploymentPlan && (this._intervaldaystarttime > 0 || this._intervaldayendtime < 24)) {
      calendarDayTime = (this._intervaldayendtime - this._intervaldaystarttime) * this.dateTimeService.onehour;
    }
    //---------------------------------

    //Intervall Length
    let diff = (this._slots.length > 1) ? (this._slots[1].start.getTime() - this._slots[0].start.getTime()) : (this.end.getTime() - this._slots[0].start.getTime());
    if (calendarDayTime > 0 && calendarDayTime < diff) {
      diff = calendarDayTime;
    }

    for (let i = 0; i < this._slots.length; i++) {

      let iStart = this._slots[i].start.getTime();
      if (calendarDayTime > 0) {
        let cStart = new Date(this._slots[i].start.getFullYear(), this._slots[i].start.getMonth(), this._slots[i].start.getDate(), this._intervaldaystarttime, 0, 0).getTime();
        if (cStart > iStart) {
          iStart = cStart;
        }
      }

      let iEnd = iStart + diff;
      if (this.precision > 0) {
        //Week or Month
        if ((i + 1) < this._slots.length) {
          iEnd = this._slots[i + 1].start.getTime();
        }
        else {
          iEnd = bEnd;
        }
      }

      if ((iStart <= bStart && bStart < iEnd) //Starts in interval
        ||
        (iStart < bEnd && bEnd <= iEnd) //Ends in interval
        ||
        (iStart > bStart && bEnd > iEnd) //Spans over whole interval
      ) {

        let calcStart = bStart < iStart ? iStart : bStart;
        let calcEnd = bEnd > iEnd ? iEnd : bEnd;

        let calc = 0;
        if (this.permissionService.permissions.BreakStart) {
          let tickTime = 0;

          let breaks = [{ Break: shift.Break, BreakStart: shift.BreakStart }];
          if (this.permissionService.permissions.MultipleBreak) {
            breaks = shift.Breaks;
          }
          for (let i = 0; i < breaks.length; i++) {
            let breakObj = breaks[i];
            bBreak = breakObj.Break * 60 * 1000;
            bBreakStart = new Date(breakObj.BreakStart).getTime();

            if (bBreak > 0) {
              let startInside = (calcStart <= bBreakStart && bBreakStart < calcEnd);
              let endInside = (calcStart < (bBreakStart + bBreak) && (bBreakStart + bBreak) <= calcEnd);
              if (startInside && endInside) {
                tickTime += bBreak;
              }
              else if (startInside) {
                tickTime += calcEnd - bBreakStart;
              }
              else if (endInside) {
                tickTime += (bBreakStart + bBreak) - calcStart;
              }
            }
          }

          calc = (calcEnd - calcStart - tickTime) / (60 * 60 * 1000);
        }
        else {
          calc = ((calcEnd - calcStart) * procentTime) / (60 * 60 * 1000);
        }

        let alreadysum = false;
        if (this.sumoptions == 1) {
          if (calc > 0) {
            calc = 1;
          }
          let binary = Math.pow(2, i);
          alreadysum = ((bits & binary) == binary);
          if (!alreadysum) {
            bits += binary;
          }
        }
        else if (this._sumoptions == 2) {
          if (calc > 0) {
            calc = 1;
          }
        }

        if (!alreadysum) {
          this._filledsum[i] += calc;
          row.filledsum[i] += calc;

          if (availablesum[i] > 0) {
            if (calc < availablesum[i]) {
              this._availabilitysum[i] -= calc;
              row.availabilitysum[i] -= calc;
            }
            else {
              this._availabilitysum[i] -= availablesum[i];
              row.availabilitysum[i] -= availablesum[i];
            }
          }
        }
      }

    }

    return bits;
  }
  private intervalNotFilledSum(row: any, shift: any) {
    let bStart = new Date(shift.Start).getTime();
    let bEnd = new Date(shift.End).getTime();
    let bBreak = shift.Break * 60 * 1000;
    let bBreakStart = new Date(shift.BreakStart).getTime();

    let procentTime = 1 - (bBreak / (bEnd - bStart));

    for (let i = 0; i < this._slots.length; i++) {

      let iDateStart = this._slots[i].start.getTime();
      let iDateEnd = this.end.getTime();
      if (i < this._slots.length - 1) { iDateEnd = this._slots[i + 1].start.getTime(); }
      let diff = iDateEnd - iDateStart;

      let iStart = this._slots[i].start.getTime();
      let iEnd = iStart + diff;
      if (this.precision > 0) {
        //Week or Month
        if ((i + 1) < this._slots.length) {
          iEnd = this._slots[i + 1].start.getTime();
        }
        else {
          iEnd = bEnd;
        }
      }

      if ((iStart <= bStart && bStart < iEnd) //Starts in interval
        ||
        (iStart < bEnd && bEnd <= iEnd) //Ends in interval
        ||
        (iStart > bStart && bEnd > iEnd) //Spans over whole interval
      ) {

        let calcStart = bStart < iStart ? iStart : bStart;
        let calcEnd = bEnd > iEnd ? iEnd : bEnd;

        let calc = 0;
        if (this.permissionService.permissions.BreakStart) {
          let tickTime = 0;

          let breaks = [{ Break: shift.Break, BreakStart: shift.BreakStart }];
          if (this.permissionService.permissions.MultipleBreak) {
            breaks = shift.Breaks;
          }
          for (let i = 0; i < breaks.length; i++) {
            let breakObj = breaks[i];
            bBreak = breakObj.Break * 60 * 1000;
            bBreakStart = new Date(breakObj.BreakStart).getTime();

            if (bBreak > 0) {
              let startInside = (calcStart <= bBreakStart && bBreakStart < calcEnd);
              let endInside = (calcStart < (bBreakStart + bBreak) && (bBreakStart + bBreak) <= calcEnd);
              if (startInside && endInside) {
                tickTime += bBreak;
              }
              else if (startInside) {
                tickTime += calcEnd - bBreakStart;
              }
              else if (endInside) {
                tickTime += (bBreakStart + bBreak) - calcStart;
              }
            }
          }

          calc = (calcEnd - calcStart - tickTime) / (60 * 60 * 1000);
        }
        else {
          calc = ((calcEnd - calcStart) * procentTime) / (60 * 60 * 1000);
        }

        if (this._sumoptions >= 1) {
          if (calc > 0) {
            calc = 1;
          }
        }

        this._notfilledsum[i] += calc * (shift.Max - shift.Amount);
        row.notfilledsum[i] += calc * (shift.Max - shift.Amount);
      }

    }
  }
  private intervalSumActivity(row: any, activity: any, availablesum: any[]) {
    let aStart = new Date(activity.Start).getTime();
    let aEnd = new Date(activity.End).getTime();

    let diff = (this._slots.length > 1) ? (this._slots[1].start.getTime() - this._slots[0].start.getTime()) : (this.end.getTime() - this._slots[0].start.getTime());
    for (let i = 0; i < this._slots.length; i++) {

      let iStart = this._slots[i].start.getTime();
      let iEnd = iStart + diff;
      if (this.precision > 0) {
        //Week or Month
        if ((i + 1) < this._slots.length) {
          iEnd = this._slots[i + 1].start.getTime();
        }
        else {
          iEnd = aEnd;
        }
      }

      if ((iStart <= aStart && aStart < iEnd) //Starts in interval
        ||
        (iStart < aEnd && aEnd <= iEnd) //Ends in interval 
        ||
        (iStart > aStart && aEnd > iEnd) //Spans over whole interval
      ) {

        let calcStart = aStart < iStart ? iStart : aStart;
        let calcEnd = aEnd > iEnd ? iEnd : aEnd;

        let calc = (calcEnd - calcStart) / (60 * 60 * 1000);
        if (this.settingService.timeline.sumoptions >= 1) {
          calc = 1;
        }
        calc *= (1 - activity.Factor);

        this._filledsum[i] -= calc;
        row.filledsum[i] -= calc;

        if (availablesum[i] > 0) {
          if (calc < availablesum[i]) {
            this._availabilitysum[i] += calc;
            row.availabilitysum[i] += calc;
          }
          else {
            this._availabilitysum[i] += availablesum[i];
            row.availabilitysum[i] += availablesum[i];
          }
        }
      }

    }
  }
  private intervalNotFilledSumActivity(row: any, activity: any, notfilled: number) {
    let aStart = new Date(activity.Start).getTime();
    let aEnd = new Date(activity.End).getTime();

    let diff = (this._slots.length > 1) ? (this._slots[1].start.getTime() - this._slots[0].start.getTime()) : (this.end.getTime() - this._slots[0].start.getTime());
    for (let i = 0; i < this._slots.length; i++) {

      let iStart = this._slots[i].start.getTime();
      let iEnd = iStart + diff;
      if (this.precision > 0) {
        //Week or Month
        if ((i + 1) < this._slots.length) {
          iEnd = this._slots[i + 1].start.getTime();
        }
        else {
          iEnd = aEnd;
        }
      }

      if ((iStart <= aStart && aStart < iEnd) //Starts in interval
        ||
        (iStart < aEnd && aEnd <= iEnd) //Ends in interval 
        ||
        (iStart > aStart && aEnd > iEnd) //Spans over whole interval
      ) {

        let calcStart = aStart < iStart ? iStart : aStart;
        let calcEnd = aEnd > iEnd ? iEnd : aEnd;

        let calc = (calcEnd - calcStart) / (60 * 60 * 1000);
        if (this.settingService.timeline.sumoptions >= 1) {
          calc = 1;
        }
        calc *= (1 - activity.Factor);

        this._notfilledsum[i] -= calc * notfilled;
        row.notfilledsum[i] -= calc * notfilled;
      }

    }
  }
  
}


export class ShiftChartSlot {
  public start: Date;
  public label: string;
}

export class ShiftChartRow {
  public id: number = 0;
  public name: string = '';
  public shifts: any[] = [];
  public availabilities: any[] = [];
  public filledsum: number[] = [];
  public notfilledsum: number[] = [];
  public availabilitysum: number[] = [];
}

export class ShiftChartCompareData {

  public name: string = ''
  public charttype: ChartType = 'line';
  public data: any[] = [];
  public color: string = 'black';
  public dash: boolean = false;
  public sum: boolean = false;
}


