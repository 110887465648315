
<swe-card [(open)]="open"[hasCollapse]="false" [header]="languageService.getItem(1393)" [hasRefresh]="true" (refreshChange)="load()" [hasEditMode]="id>0&&permissionService.permissions.GuideAccess>1">
  <span swe-rightcommand><i [ngClass]="{'bi-lock-fill': locked, 'bi-unlock-fill': !locked}" class="bi bi-swe-pull-right swe-click" (click)="lock=!lock" *ngIf="systemAdmin&&id!=0"></i></span>
  <ng-container *ngIf="!isLoading && guide && permissionService.permissions.GuideAccess > 0">
    <swe-element type="System.String" [statusLabel]="editmode ? 2 : 1" [label]="languageService.getItem(130)" [(model)]="guide.Name" [access]="editmode?3:1" [disabled]="locked"></swe-element>
    <swe-element type="System.List" [statusLabel]="editmode ? 2 : 1" [label]="languageService.getItem(1391)" [items]="languages" [(model)]="guide.Culture" [access]="editmode?3:1" [disabled]="locked"></swe-element>
    <swe-element type="System.MultiList" [container]="{isbit: true, max:6}" [statusLabel]="editmode ? 2 : 1" [label]="languageService.getItem(1)" [(model)]="guide.Keywords" [items]="generalService.guideKeywords" [access]="editmode?3:1" [disabled]="locked"></swe-element>
    <!--Access-->
    <swe-card [open]="locked" [header]="languageService.getItem(142)" [hasRefresh]="false">
      <swe-element type="System.Boolean" [statusLabel]="editmode?2:1" [label]="languageService.getItem(343)" [(model)]="guide.IsActive" [access]="editmode?3:1"></swe-element>
      <swe-element type="System.MultiList" [statusLabel]="editmode ? 2 : 1" [label]="languageService.getItem(82)" [(model)]="guide.Roles" [items]="generalService.roles" [access]="editmode?3:1"></swe-element>
      <div class="row" *ngIf="permissionService.user.IsSuper">
        <div class="col-8 mb-2" *ngIf="editmode">
          <span>{{languageService.getItem(607)}}</span>
        </div>
        <div class="col-2 mb-2" *ngIf="editmode">
          <span> {{languageService.getItem(142)}}</span>
        </div>
        <ng-container *ngFor="let item of selectedRules; index as i;">
          <div class="col-8">
            <swe-element type="System.List" [access]="editmode?3:1" [label]="languageService.getItem(607)" [(model)]="item.RuleId" [items]="ruleKeywords"></swe-element>
          </div>
          <div class="col-2">
            <swe-element type="System.List" [access]="editmode?3:1" [label]="languageService.getItem(142)" [(model)]="item.Access" [items]="access"></swe-element>
          </div>
          <div class="col-2 mt-2" *ngIf="editmode">
            <a class="swe-no-link ms-2" href="javascript:void(0);" [attr.title]="languageService.getItem(17)" (click)="removeRule(i)" *ngIf="i > 0"><i class="bi bi-swe-fw bi-dash-circle-fill"></i></a>
            <a class="swe-no-link ms-2" href="javascript:void(0);" [attr.title]="languageService.getItem(100)" (click)="addRule()" *ngIf="i == 0"><i class="bi bi-swe-fw bi-plus-circle-fill"></i></a>
          </div>
        </ng-container>
          <button *ngIf="locked" class="btn btn-primary" (click)="saveAccess()"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}} {{languageService.getItem(142).toLowerCase()}}</button>
      </div>
    </swe-card>
    <!--Content-->
    <swe-card [hasRefresh]="false" [open]="false" [header]="languageService.getItem(1399)">
      <span swe-rightcommand><i class="bi bi-plus-lg bi-swe-pull-right swe-click" (click)="addContent($event)" *ngIf="editmode"></i></span>
      <swe-card *ngFor="let content of guide.Contents" [hasRefresh]="false" [header]="languageService.getItem(1399) + ' ' + content.SortOrder">
        <swe-element type="System.Int32" [access]="editmode?3:1" [label]="languageService.getItem(490)" [statusLabel]="editmode ? 2 : 1" [(model)]="content.SortOrder" [disabled]="locked"> </swe-element>
        <div>
          <label class="col-form-label" *ngIf="editmode">
            <span>
              {{languageService.getItem(1399)}}
            </span>
          </label>
          <div class="btn-group bi-swe-pull-right" role="group">
            <div class="dropdown" *ngIf="editmode&&!locked">

              <button type="button" class="btn btn-outline-primary" (click)="content.ShowHeaderMenu=!content.ShowHeaderMenu"><i class="bi bi-alphabet-uppercase" ></i></button>
              <div class="dropdown-menu" [ngClass]="{'show': content.ShowHeaderMenu}">
                <div class="input-group">
                  <div class="btn-group mx-2">
                    <button class="btn btn-outline-primary" *ngFor="let header of headers" (click)="insertHeader(header.Id,content)">
                      {{header.Name}}
                    </button>
                  </div>
                  <input type="text" placeholder="..." class="form-control mx-2" [(ngModel)]="content.Header" />
                </div>
              </div>
            </div>
            <div class="dropdown mx-1" *ngIf="editmode&&!locked">

              <button type="button" (click)="content.ShowBoldMenu=!content.ShowBoldMenu" class="btn btn-outline-primary"><i class="bi bi-type-bold"></i></button>
              <div class="dropdown-menu" [ngClass]="{'show': content.ShowBoldMenu}">
                <div class="input-group" style="min-width:200px">
                  <input type="text" placeholder="..." class="form-control ms-2" [(ngModel)]="content.BoldContent" />
                  <button class="btn btn-primary me-2" type="button" (click)="insertBold(content)"><i class="bi bi-plus-circle"></i></button>
                </div>
              </div>
            </div>

            <div class="dropdown" *ngIf="editmode&&!locked">
              <button type="button" (click)="content.ShowVideoMenu=!content.ShowVideoMenu" class="btn btn-outline-primary"><i class="bi bi-play-btn"></i></button>
              <div class="dropdown-menu" [ngClass]="{'show': content.ShowVideoMenu}">
                <div class="input-group" style="min-width:200px">
                  <input type="text" placeholder="jn_MDRtPtaQ" class="form-control ms-2" [(ngModel)]="content.VideoId" />
                  <button class="btn btn-primary me-2" type="button" (click)="insertVideo(content)"><i class="bi bi-plus-circle"></i></button>
                </div>
              </div>
            </div>
          </div>
          <textarea class="form-control my-1" rows="10" [id]="'content-'+content.SortOrder" *ngIf="editmode" [(ngModel)]="content.Body" [disabled]="locked"></textarea>
          <p *ngIf="!editmode">{{content.Body}}</p>
        </div>
      </swe-card>
    </swe-card>

    <div class="d-grid gap-2">
      <button *ngIf="permissionService.permissions.GuideAccess > 1" [disabled]="locked" class="btn btn-primary" (click)="save()"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
      <swe-confirm *ngIf="id>0&&permissionService.permissions.GuideAccess > 2&&!systemAdmin" [body]="languageService.getItem(1394)" (acceptChange)="delete()">
        <button class="btn btn-danger"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
      </swe-confirm>
    </div>
  </ng-container>
</swe-card>
