<swe-pageheader class="swe-print-remove" [header]="languageService.getItem(289)"><span swe-hits>&nbsp;({{hits}}<span *ngIf="more">+</span>)</span></swe-pageheader>
<div class="swe-print-remove">
  <!--DateTime Pager-->
  <swe-datepager [type]="'booking'" (dateChange)="search(false)" [disabled]="loading" [hideTime]="true"></swe-datepager>
  <!--Reports-->
  <div class="btn-group me-1 align-top" [attr.title]="languageService.getItem(289)">
    <div class="dropdown">
      <button [attr.aria-label]="languageService.getItem(289)" class="btn btn-swe" (click)="reportmenu=!reportmenu" [disabled]="loading">
        <span>{{settingService.report.name.length > 0 ? settingService.report.name : languageService.getItem(308)}}&nbsp;<i class="bi bi-caret-down-fill"></i></span>
      </button>
      <ul class="dropdown-menu" [ngClass]="{'show': reportmenu }">
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.ReportNew" (click)="changereport({Id: 0})"><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg': settingService.report.id==0}"></i>{{languageService.getItem(308)}}</li>
        <ng-container *ngFor="let group of reports | keyvalue">
          <li class="dropdown-divider"></li>
          <li class="dropdown-header">{{group.value.header}}</li>
          <li class="dropdown-item swe-click" *ngFor="let item of group.value.items" (click)="changereport(item)"><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg': settingService.report.id==item.Id}"></i>{{item.Name}}</li>
        </ng-container>
      </ul>
    </div>
    <button [attr.aria-label]="languageService.getItem(883)" class="btn btn-swe" (click)="editmenu=!editmenu" [disabled]="loading">
      <span><i class="bi bi-swe-fw bi-pencil-square" [ngClass]="{'swe-grayed-out': editmenu}"></i></span>
    </button>
  </div>
  <!--All Button-->
  <div class="btn-group ms-1 align-top" *ngIf="all">
    <button class="btn btn-danger" (click)="search(false, true)">{{languageService.getItem(340)}}</button>
  </div>
  <!--Active Filters-->
  <swe-filteritems></swe-filteritems>
</div>
<!--Report table-->
<div class="row py-2">
  <div class="col-12 col-md-4" *ngIf="editmenu">
    <swe-card [header]="settingService.report.name.length > 0 ? settingService.report.name : languageService.getItem(308)"
              (refreshChange)="search(false)"
              [hasCollapse]="false"
              [hasSave]="true" (saveChange)="save()"
              [hasClose]="true" (closeChange)="editmenu=false">
      <span swe-rightcommand>
        <i class="bi bi-swe-fw bi-box-arrow-up-right swe-click" (click)="goto()" *ngIf="settingService.report.id>0"></i>
      </span>
      <swe-element [statusLabel]="1" [label]="languageService.getItem(307)" [(model)]="settingService.report.name" [disabled]="editpermissions<2" [type]="'System.String'"></swe-element>
      <swe-element [statusLabel]="1" [label]="languageService.getItem(861)" [(model)]="settingService.report.description" [disabled]="editpermissions<2" [type]="'System.TextArea'"></swe-element>
      <swe-element [statusLabel]="1" [label]="languageService.getItem(306)" [(model)]="settingService.report.main" (modelChange)="manageExcludes()" [items]="maintypes" [type]="'System.List'"></swe-element>
      <swe-element [statusLabel]="1" [label]="languageService.getItem(156)" [(model)]="settingService.report.reports" [items]="reportproperties" [disabled]="editpermissions<2" [type]="'System.GroupByMultiList'" [container]="{max: 20}"></swe-element>
      <swe-element [statusLabel]="1" [label]="languageService.getItem(833)" [(model)]="settingService.report.visibility" [items]="reportvisibility" [disabled]="settingService.report.id>0" [type]="'System.GroupByList'"></swe-element>
      <swe-card [header]="languageService.getItem(359)" [color]="'swe'" [hasCollapse]="true" [hasRefresh]="false" [open]="false">
        <swe-element [statusLabel]="1" [label]="languageService.getItem(1056)" [(model)]="removeOrder" [type]="'System.Boolean'" *ngIf="settingService.report.order.length>0"></swe-element>
        <swe-element [statusLabel]="1" [label]="languageService.getItem(1057)" [(model)]="removeSort" [type]="'System.Boolean'" *ngIf="sorted.length>0"></swe-element>
        <swe-element [statusLabel]="1" [label]="languageService.getItem(274)" [(model)]="settingService.report.groupby" [items]="groupbyproperties" [disabled]="editpermissions<2" [type]="'System.List'" [optional]="true"></swe-element>
        <swe-element [statusLabel]="1" [label]="languageService.getItem(948)" [(model)]="settingService.report.excludeobject" [disabled]="editpermissions<2" [items]="excludetypes" [container]="{isbit:true, min: 3}" [type]="'System.MultiList'"></swe-element>
      </swe-card>
      <div class="d-grid gap-2">
        <i class="small">{{languageService.getItem(704)}}</i>
        <button class="btn btn-primary" (click)="save()" *ngIf="editpermissions>1"><i class="bi bi-swe-fw bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
        <button class="btn btn-primary" (click)="copy()" *ngIf="settingService.report.id>0&&permissionService.permissions.ReportNew"><i class="bi bi-swe-fw bi-copy"></i>&nbsp;{{languageService.getItem(835)}}</button>
        <swe-confirm *ngIf="settingService.report.id>0&&editpermissions>2" [body]="languageService.getItem(767)" (acceptChange)="delete()">
          <button class="btn btn-danger"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
        </swe-confirm>
      </div>
    </swe-card>
  </div>
  <div class="swe-print-full-width" [ngClass]="{'col-12': !editmenu, 'col-md-8': editmenu}">
    <swe-card [header]="settingService.report.description" [hasCollapse]="false" (refreshChange)="search(false)" [fitcontent]="false" [rulelist]="['SweReports', 'SweReportCreate']" [hasZoom]="true">
      <span class="swe-print-add" swe-leftcommand>
        {{interval()}},&nbsp;{{settingService.report.name}},&nbsp;
      </span>
      <div class="d-inline swe-print-remove" swe-rightcommand>
        <i class="bi bi-swe-fw bi-plus-lg bi-swe-pull-right swe-click" (click)="open(0, $event)" *ngIf="createvisible" [attr.title]="languageService.getItem(687)"></i>
        <div class="btn-group me-2 float-end" *ngIf="templates.length>0">
          <div class="dropdown">
            <i class="bi bi-swe-fw bi-caret-down-fill swe-click" (click)="downloadmenu=!downloadmenu;$event.stopPropagation()" [attr.title]="languageService.getItem(852)" data-bs-toggle="dropdown"></i>
            <ul class="dropdown-menu dropdown-menu-end" [ngClass]="{'show': downloadmenu }">
              <li class="dropdown-item swe-click" (click)="download(0, $event)"><a><i class="bi bi-swe-fw bi-download"></i>&nbsp;CSV</a></li>
              <li class="dropdown-item swe-click" *ngFor="let template of templates" (click)="download(template.Key, $event)"><a><i class="bi bi-swe-fw bi-download"></i>&nbsp;{{template.Value}}</a></li>
            </ul>
          </div>
        </div>
        <i class="bi bi-swe-fw bi-download bi-swe-pull-right swe-click" (click)="download(0, $event)" [attr.title]="languageService.getItem(851)"></i>
        <div class="btn-group float-end">
          <div class="dropdown">
            <i class="bi bi-swe-fw bi-sliders2 swe-click" [ngClass]="{'swe-grayed-out': !downloadsettingmenu}" (click)="downloadsettingmenu=!downloadsettingmenu;$event.stopPropagation()" [attr.title]="languageService.getItem(770)" data-bs-toggle="dropdown"></i>
            <ul class="dropdown-menu dropdown-menu-end" [ngClass]="{'show': downloadsettingmenu }">
              <li class="dropdown-item swe-click" *ngFor="let enc of encodings" (click)="changeencoding(enc.Id, $event)"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg': encoding==enc.Id}"></i>{{enc.Name}}</a></li>
              <li class="dropdown-divider"></li>
              <li class="dropdown-item swe-click" *ngFor="let del of delimeters" (click)="changedelimeter(del.Id, $event)"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg': delimeter==del.Id}"></i>{{del.Name}}</a></li>
            </ul>
          </div>
        </div>
        <i class="bi bi-swe-fw bi-printer bi-swe-pull-right swe-click me-2" (click)="print()"></i>
      </div>
      <div *ngIf="graphvisible.length>0">
        <canvas baseChart
                [datasets]="chartdata"
                [labels]="chartlabels"
                [options]="chartoptions"
                [legend]="chartlegend"
                [chartType]="charttype"
                height="50">
        </canvas>
      </div>
      <div class="table-responsive swe-report-table" zoomContent>
        <table class="table table-sm table-striped table-bordered">
          <thead>
            <tr>
              <th style="width:90px;" *ngIf="hascheckicon||hasicons">
                <div class="btn-group" style="vertical-align:top;" *ngIf="hascheckicon">
                  <div class="dropdown">
                    <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe" (click)="allchecked=!allchecked;listutility.checkall(allchecked)">
                      <span><i class="bi bi-check-lg"></i></span>
                    </button>
                    <button [attr.aria-label]="languageService.getItem(843)" class="btn btn-swe" (click)="actionmenu=!actionmenu" [attr.disabled]="listutility.ischecked ? null : true">
                      <span><i class="bi bi-swe-fw bi-three-dots-vertical"></i></span>
                    </button>
                    <ul class="dropdown-menu" [ngClass]="{'show': actionmenu && listutility.ischecked}">
                      <li class="dropdown-item swe-click"><a (click)="action('multi')"><i class="bi bi-swe-fw bi-copy"></i>&nbsp;{{languageService.getItem(410)}}</a></li>
                      <li class="dropdown-item swe-click" *ngIf="settingService.report.main==0&&permissionService.permissions.MultiTimereport>0"><a (click)="multitimereport()"><i class="bi bi-swe-fw bi-clock"></i>&nbsp;{{languageService.getItem(52)}}</a></li>
                      <li class="dropdown-divider" *ngIf="(settingService.report.main==0&&(permissionService.permissions.Email>0||permissionService.permissions.Sms>0))||(settingService.report.main==1&&permissionService.permissions.Email>0)"></li>
                      <li class="dropdown-item swe-click" *ngIf="(settingService.report.main==0||settingService.report.main==1)&&permissionService.permissions.Email>0"><a (click)="send(1)"><i class="bi bi-swe-fw bi-envelope-fill"></i>&nbsp;{{languageService.getItem(321)}}</a></li>
                      <li class="dropdown-item swe-click" *ngIf="settingService.report.main==0&&permissionService.permissions.Sms>0"><a (click)="send(3)"><i class="bi bi-swe-fw bi-phone-fill"></i>&nbsp;{{languageService.getItem(320)}}</a></li>
                    </ul>
                  </div>
                </div>
              </th>
              <ng-container *ngFor="let header of headers;index as i">
                <th class="swe-click" *ngIf="i>lastsystemcolumnindex">
                  <div *ngIf="editmenu">
                    &nbsp;
                    <div class="float-start">
                      <i class="bi bi-swe-fw bi-caret-left-fill swe-print-remove" *ngIf="i>(firstnonsystemcolumnindex+1)||(i==(firstnonsystemcolumnindex+1)&&settingService.report.groupby==0)" (click)="move(header,-1)"></i>
                    </div>
                    <div class="float-end">
                      <i class="bi bi-swe-fw bi-caret-right-fill swe-print-remove" *ngIf="(i>firstnonsystemcolumnindex||(i==firstnonsystemcolumnindex&&settingService.report.groupby==0))&&i<headers.length-1" (click)="move(header,1)"></i>
                    </div>
                  </div>
                  <div (click)="sort(header)">
                    {{header.property.Name}}
                    <i class="bi bi-swe-fw bi-bar-chart-fill swe-print-remove" *ngIf="header.property.Graph>0" [ngClass]="{'swe-grayed-out': (header.property.Graph==1)}" (click)="graph(header,$event)"></i>
                    <i class="bi bi-swe-fw bi-caret-down-fill swe-print-remove" *ngIf="header.property.Sort==1"></i>
                    <i class="bi bi-swe-fw bi-caret-up-fill swe-print-remove" *ngIf="header.property.Sort==2"></i>
                  </div>
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr class="swe-click" *ngFor="let row of rows;index as j" (click)="open(row[0], $event)">
              <td style="width:90px;" *ngIf="hascheckicon||hasicons" (click)="hascheckicon && listutility.toggle(row, $event)" [ngClass]="{'swe-active-cell swe-first-cell': j==activerow}">
                <i class="bi bi-swe bi-check-circle-fill" *ngIf="row.checked"></i>
                <span *ngIf="hasicons && row[2]">
                  <ng-container  *ngFor="let iconclass of row[2].split('|'); index as i">
                    <i class="{{iconclass}}" *ngIf="!row.checked||i > 0"></i>
                  </ng-container>
                </span>
              </td>
              <ng-container *ngFor="let cell of row;index as i">
                <td *ngIf="i>lastsystemcolumnindex" [ngClass]="{'swe-active-cell': (j==activerow), 'swe-first-cell': (j==activerow&&!hascheckicon&&!hasicons&&i==firstnonsystemcolumnindex), 'swe-last-cell': (j==activerow&&i==row.length-1)}">
                  <ng-container *ngIf="i==firstnonsystemcolumnindex">
                    <!--WCAG tab been rows-->
                    <a class="swe-no-link swe-no-focus" href="javascript:void(0);" (click)="open(row[0], $event)" (focus)="activerow=j" (blur)="activerow=-1"><swe-reportcell [data]="cell" [header]="headers[i]" [bold]="settingService.report.groupby>0"></swe-reportcell></a>
                  </ng-container>
                  <ng-container *ngIf="i>firstnonsystemcolumnindex">
                    <swe-reportcell [data]="cell" [header]="headers[i]"></swe-reportcell>
                  </ng-container>
                </td>
              </ng-container>
            </tr>
          </tbody>
          <tfoot *ngIf="!loading">
            <tr>
              <td style="width:90px;" *ngIf="hascheckicon||hasicons">&nbsp;</td>
              <ng-container *ngFor="let cellsum of sum; index as i">
                <td *ngIf="i>lastsystemcolumnindex">
                  <div><b>{{cellsum}}</b></div>
                  <div *ngIf="cellsum"><b>&mu;&nbsp;{{generalService.formatdecimal(cellsum/sumlength[i])}}</b></div>
                </td>
              </ng-container>
            </tr>
          </tfoot>
        </table>
        <div class="d-grid gap-2">
          <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
        </div>
      </div>
    </swe-card>
  </div>
</div>
