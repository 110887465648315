import { Component, OnInit } from '@angular/core';
import { PermissionService } from '../../_services/permission.service';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'swe-guidedetail',
  templateUrl: './guidedetail.component.html'
})
export class GuideDetailComponent implements OnInit {
  private _id: number;
  private _systemAdmin: boolean;

  public get systemAdmin() {
     return this._systemAdmin;
  }

  constructor(public permissionService: PermissionService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this._id = Number(params.get('id'));
    });
    this.route.data.subscribe((val) => {
      this._systemAdmin = val.systemAdmin;
    });
  }

  public get id() {
    return this._id;
  }
}
