import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'swe-dropdown',
  templateUrl: './dropdown.component.html',
})
export class DropdownComponent {

  @Input() isOpen: boolean;
  @Input() icon: string;
  @Input() disabled: boolean;
  @Input() label: string;
  @Input() loading: boolean;
    @Input() collapsed: boolean;
  @Output() isOpenChange = new EventEmitter<any>()



  constructor() {
    
  }

  public close() {
    this.isOpen = false;
    this.isOpenChange.emit(this.isOpen);
  }
  public buttonClick() {
    this.isOpen = !this.isOpen;
    this.isOpenChange.emit(this.isOpen);
}
}
