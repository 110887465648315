import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LanguageService } from '../../../_services/language.service';
import { DataService } from '../../../_services/data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';




@Component({
  selector: 'swe-guidepreview',
  templateUrl: './guidepreview.component.html'
})
export class GuidePreviewComponent implements OnInit {
  @Input() id: number;
  @Input() alwaysOpen: boolean = false;
  @Input() title: string = '';
  @Input() hasRefresh: boolean = true;
  @Input() hasEdit: boolean = false;
  @Input() hasClose: boolean = false;
  @Input() isBaseGuide: boolean = false;

  @Output() closed = new EventEmitter<any>();

  private _isLoading: boolean = false;
  private _activeIndex: number = 0;
  private _contents: any[] = [];
  private _isOpen: boolean = false;
  private _baseUrl: string = '/api/v1/guides/';

  constructor(public languageService: LanguageService, private dataService: DataService, private sanitizer: DomSanitizer, private router: Router) { }


  ngOnInit() {
    if (this.isBaseGuide)
    {
      this._baseUrl = '/api/v1/guides/base/';
    }
    if (this.alwaysOpen) {
      this._isOpen = true;
      this.load();
    }
  }
  public get header() {
    return this.title ? this.title : this.languageService.getItem(1392);
  }
  public get activeIndex() {
    return this._activeIndex;
  }
  public get isOpen() {
    if (this.alwaysOpen) {
      return true;
    }
    return this._isOpen;
  }
  public set isOpen(value) {
    this._isOpen = value;
  }

  public get contents() {
    return this._contents;
  }

  public get isLoading() {
    return this._isLoading;
  }

  public close() {
    this.closed.emit();
  }

  public prev() {
    this._activeIndex--;
    if (this._activeIndex < 0) {
      this._activeIndex = this._contents.length - 1;
    }
  }
  public next() {
    this._activeIndex++;
    if (this._activeIndex > this._contents.length - 1) {
      this._activeIndex = 0;
    }
  }

  public goTo() {
    if (this.isBaseGuide) {
      this.router.navigate(['/systemadmin/guides', this.id]);
      return;
    }
    
    this.router.navigate(['/admin/guides', this.id]);
  }

  public load() {
    if (!this._isOpen) {
      return;
    }
    this._isLoading = true;
    this.dataService.tokenRequest(this._baseUrl + this.id + '/contents', 'GET').subscribe((res) => {
      if (res) {
        this._contents = [];
        res.forEach(c => {
          this._contents.push(this.sanitizer.bypassSecurityTrustHtml(c.Html));
        });
      }
        this._isLoading = false;
    });
  }
}
