<swe-pageheader [header]="languageService.getItem(410)"></swe-pageheader>
<div class="row d-block py-2">
  <div class="col-12 col-md-4 float-start">
    <swe-card header="{{languageService.getItem(4)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false" [rulelist]="['SweMultiShift']">
      <!--Time-->
      <div class="mb-3">
        <label>{{languageService.getItem(931)}}</label>
        <div>
          <swe-datetime [(start)]="start" [(end)]="end" [labelStart]="languageService.getItem(931)" [hideDate]="true"></swe-datetime>
        </div>
      </div>
      <!--Status-->
      <swe-element [(model)]="status" [label]="languageService.getItem(413)" [statusLabel]="2" [items]="getStatus()" [optional]="true" [type]="'System.List'"></swe-element>
      <!--Level-->
      <swe-adminrule [rulelist]="['SweMultiShiftLevel']"></swe-adminrule>
      <swe-element [(model)]="levelId" type="System.LevelSearch" [container]="{type: 'Booking' }" [statusLabel]="2" [label]="languageService.getItem(1276)" *ngIf="permissionService.permissions.MultiShiftLevel"></swe-element>
      <!--Owner-->
      <swe-adminrule [rulelist]="['SweOwner']"></swe-adminrule>
      <swe-element [(model)]="ownerId" [label]="languageService.getItem(654)" [statusLabel]="2" [container]="{'onlyone':true}" [type]="'System.UserSearch'" *ngIf="permissionService.permissions.Owner>1"></swe-element>
      <!--Activities-->
      <div *ngIf="permissionService.permissions.Activity>1">
        <swe-card [header]="languageService.getItem(395)" [color]="'swe'" [open]="false" [hasRefresh]="false" [rulelist]="['SweHasActivities']">
          <span swe-rightcommand><i class="bi bi-plus-lg bi-swe-pull-right swe-click" *ngIf="permissionService.permissions.Activity>1&&activities&&activities.length>0&&!activities[0].isedit" (click)="editActivity(activities[0], $event)"></i></span>
          <div class="list-group">
            <ng-container *ngFor="let activity of activities">
              <div class="list-group-item list-group-item-action d-flex flex-wrap swe-click" (click)="editActivity(activity, $event)" *ngIf="!activity.isedit && activity.Id!=0">
                <div class="col-4 col-md-4">{{activity.dateheader}}</div>
                <div class="col-8 col-md-4"><div style="display:inline-block;width:10px;height:10px;border:1px solid #999999;" [ngStyle]="{'background-color': activity.Color}"></div>&nbsp;{{activity.Type}}</div>
                <div class="col-12 col-md-3">{{activity.Text}}</div>
                <div class="col-12 col-md-1"><i class="bi bi-swe-fw bi-swe bi-trash-fill bi-swe-pull-right swe-click" (click)="deleteActivity(activity,$event)"></i></div>
              </div>
              <swe-card [header]="languageService.getItem(1018)" [color]="'swe'" [open]="true" [hasRefresh]="false" [hasCollapse]="false" *ngIf="activity.isedit">
                <span swe-rightcommand><i class="bi bi-x-lg bi-swe-pull-right swe-click" (click)="editActivity(activity, $event)"></i></span>
                <div class="mb-1">
                  <span>{{languageService.getItem(1291)}}</span>
                </div>
                <swe-datetime [(start)]="activity.Start" [(end)]="activity.End" [labelStart]="languageService.getItem(396)" [labelEnd]="languageService.getItem(397)" [hideDate]="true" *ngIf="permissionService.permissions.ShiftTime"></swe-datetime>
                <swe-element [(model)]="activity.TypeId" [statusLabel]="2" [label]="languageService.getItem(398)" [items]="generalService.activitytypes" [type]="'System.ColorList'"></swe-element>
                <swe-element [(model)]="activity.Text" [statusLabel]="2" [label]="languageService.getItem(399)" [type]="'System.String'"></swe-element>
                <div class="d-grid gap-2">
                  <button class="btn btn-primary" (click)="saveActivity(activity, $event)"><i class="bi bi-swe-fw bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
                  <button class="btn btn-danger" (click)="deleteActivity(activity, $event)"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
                </div>
              </swe-card>
            </ng-container>
          </div>
        </swe-card>
      </div>
      <!--Ignore ATK-->
      <swe-adminrule [rulelist]="['SweRemoveATK']"></swe-adminrule>
      <swe-element [(model)]="ignoreATK" [label]="languageService.getItem(1275)" [statusLabel]="2" type="System.Boolean" *ngIf="permissionService.permissions.RemoveATK"></swe-element>
      <!--Auto-->
      <swe-adminrule [rulelist]="['SweBookingAutoFlag']"></swe-adminrule>
      <swe-element [(model)]="autooption" [label]="languageService.getItem(695)" [statusLabel]="2" [items]="autooptions" [type]="'System.RadioList'" *ngIf="permissionService.permissions.AutoBook>1"></swe-element>
      <!--EmploymentPlan-->
      <swe-adminrule [rulelist]="['SweEmploymentPlan']"></swe-adminrule>
      <swe-element [(model)]="employmentoption" [label]="languageService.getItem(698)" [statusLabel]="2" [items]="employmentoptions" [type]="'System.RadioList'" *ngIf="permissionService.permissions.EmploymentPlan>1"></swe-element>
      <!--Properties-->
      <swe-properties [label]="languageService.getItem(415)+' (' + languageService.getItem(4) + ')'" [statusLabel]="2" [properties]="properties" [items]="chosenproperties" *ngIf="properties.length>0"></swe-properties>
      <!--Buttons-->
      <div class="d-grid gap-2">
        <swe-confirm [body]="confirmsave()" (acceptChange)="save()">
          <button class="btn btn-primary"><i class="bi bi-swe-fw bi-copy"></i>&nbsp;{{languageService.getItem(411)}}</button>
        </swe-confirm>
        <swe-confirm [body]="languageService.getItem(417)" (acceptChange)="delete()">
          <button class="btn btn-danger"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(412)}}</button>
        </swe-confirm>
      </div>
      <div class="mt-4">
        <swe-card [color]="'swe'" [open]="true" [hasRefresh]="false" [hasCollapse]="false" *ngIf="bookings&&bookings.length>0">
          <div class="row py-2">
            <div class="col-12" *ngFor="let booking of bookings">
              <div class="list-group-item list-group-item-action d-flex flex-wrap bg-light">
                <div class="col-6 col-md-2"><swe-element [(model)]="booking.Checked" [label]="'Checked'" [statusLabel]="0" [type]="'System.Boolean'"></swe-element></div>
                <div class="col-6 col-md-2 swe-click" (click)="goto(booking, $event)">{{booking.Id}}</div>
                <div class="col-12 col-md-4 swe-click" (click)="goto(booking, $event)">{{booking.StartFormat}}</div>
                <div class="col-12 col-md-4 swe-click" (click)="goto(booking, $event)">{{booking.EndFormat}}</div>
              </div>
            </div>
          </div>
        </swe-card>
      </div>
    </swe-card>
      </div>
  <div class="col-12 col-md-4 float-start">
    <swe-card header="{{languageService.getItem(2)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false" *ngIf="bookedusers.length>0||permissionService.permissions.BookingUsersAccess>0||availableusers.length>0" [rulelist]="['SweBookingUsers']">
      <!--Cancel Users-->
      <swe-element [bottomMargin]="0" [(model)]="cancelusers" [label]="languageService.getItem(414)" [statusLabel]="2" [items]="bookedusers" [type]="'System.MultiList'" *ngIf="bookedusers.length>0"></swe-element>
      <div class="btn-group mb-3" *ngIf="bookedusers.length>0">
        <!--Email Notification-->
        <button [attr.aria-label]="languageService.getItem(920)" [attr.aria-title]="languageService.getItem(921)" class="btn btn-swe" (click)="checknotificationcancellationemail=!checknotificationcancellationemail" *ngIf="permissionService.permissions.NotifyOnCancellationEmail>0">
          <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !checknotificationcancellationemail, 'bi-check-square': checknotificationcancellationemail}"></i>
          <i class="bi bi-swe-fw bi-swe bi-envelope-fill"></i>
        </button>
        <!--SMS Notification-->
        <button [attr.aria-label]="languageService.getItem(921)" [attr.aria-title]="languageService.getItem(921)" class="btn btn-swe" (click)="checknotificationcancellationsms=!checknotificationcancellationsms" *ngIf="permissionService.permissions.NotifyOnCancellationSms>0">
          <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !checknotificationcancellationsms, 'bi-check-square': checknotificationcancellationsms}"></i>
          <i class="bi bi-swe-fw bi-swe bi-phone-fill"></i>
        </button>
      </div>
      <!--Book Users-->
      <ng-container *ngIf="permissionService.permissions.BookingUsersAccess>0">
        <swe-element [bottomMargin]="0" [(model)]="bookingtypeoption" [label]="languageService.getItem(1039)" [statusLabel]="2" [items]="bookingtypeoptions" [type]="'System.List'"></swe-element>
        <swe-usersearch [bottomMargin]="3" (modelChange)="userChosen($event)" [onlyone]="false" [label]="languageService.getItem(435)"></swe-usersearch>
        <div class="list-group" *ngIf="availableusers.length>0">
          <div class="list-group-item" *ngFor="let user of availableusers">
            <i class="bi bi-swe-border bi-person-fill" *ngIf="user.Type==1" [attr.title]="languageService.getItem(476)"></i>
            <i class="bi bi-swe-border bi-recycle" *ngIf="user.Type==0" [attr.title]="languageService.getItem(423)"></i>
            <i class="bi bi-swe-border bi-person-bounding-box" *ngIf="user.Type==2" [attr.title]="languageService.getItem(279)"></i>
            <i class="bi bi-swe-border bi-person-x-fill" *ngIf="user.Type==3" [attr.title]="languageService.getItem(280)"></i>
            {{user.Firstname}} {{user.Lastname}}<span *ngIf="user.Username.length>0">&nbsp;({{user.Username}})</span>
            <i class="bi bi-swe-fw bi-swe bi-x-lg bi-swe-pull-right swe-click" (click)="removeuser(user,$event)"></i>
          </div>
        </div>
      </ng-container>
      <div class="mb-3">
        <div class="btn-group" *ngIf="availableusers.length>0">
          <!--Email Notification-->
          <button [attr.aria-label]="languageService.getItem(920)" [attr.aria-title]="languageService.getItem(921)" class="btn btn-swe" (click)="checknotificationemail=!checknotificationemail" *ngIf="permissionService.permissions.NotifyOnBookEmail>0">
            <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !checknotificationemail, 'bi-check-square': checknotificationemail}"></i>
            <i class="bi bi-swe-fw bi-swe bi-envelope-fill"></i>
          </button>
          <!--SMS Notification-->
          <button [attr.aria-label]="languageService.getItem(921)" [attr.aria-title]="languageService.getItem(921)" class="btn btn-swe" (click)="checknotificationsms=!checknotificationsms" *ngIf="permissionService.permissions.NotifyOnBookSms>0">
            <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !checknotificationsms, 'bi-check-square': checknotificationsms}"></i>
            <i class="bi bi-swe-fw bi-swe bi-phone-fill"></i>
          </button>
        </div>
        <!--Reminder SMS-->
        <div class="btn-group ms-2" *ngIf="permissionService.permissions.RemindSms&&availableusers.length>0">
          <button [attr.aria-label]="languageService.getItem(538)" [attr.title]="languageService.getItem(538)" class="btn btn-swe" (click)="checkremind=!checkremind">
            <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !checkremind, 'bi-check-square': checkremind}"></i>
            <i class="bi bi-swe-fw bi-swe bi-chat-fill"></i>
          </button>
        </div>
      </div>
      <!--Remove RemindSMS-->
      <swe-element [(model)]="removeremindsms" [label]="languageService.getItem(918)" [statusLabel]="2" [type]="'System.Boolean'" *ngIf="permissionService.permissions.RemindSms"></swe-element>
      <!--Properties-->
      <swe-properties [label]="languageService.getItem(415)+' (' + languageService.getItem(1040) + ')'" [statusLabel]="2" [properties]="timereportproperties" [items]="chosentimereportproperties" *ngIf="permissionService.permissions.BookingUsersAccess>0&&timereportproperties.length>0"></swe-properties>
      <!--Buttons-->
      <div class="d-grid gap-2">
        <swe-confirm [body]="confirmsave()" (acceptChange)="save()">
          <button class="btn btn-primary"><i class="bi bi-swe-fw bi-copy"></i>&nbsp;{{languageService.getItem(411)}}</button>
        </swe-confirm>
        <swe-confirm [body]="languageService.getItem(417)" (acceptChange)="delete()">
          <button class="btn btn-danger"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(412)}}</button>
        </swe-confirm>
      </div>
    </swe-card>
  </div>
</div>

