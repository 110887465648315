<swe-pageheader [header]="languageService.getItem(1009)">
  <span swe-hits>
    &nbsp;(#{{id}})
  </span>
</swe-pageheader>
<div class="row py-2">
  <div class="col-12">
    <swe-card [open]="true" [hasCollapse]="false" [hasRefresh]="false" *ngIf="permissionService.permissions.Templates>0">
      <swe-element [type]="'System.Text'" [label]="languageService.getItem(509)" [statusLabel]="2" [(model)]="template.Name"></swe-element>
      <div class="mb-3">
        <swe-element [bottomMargin]="0" [type]="'System.LevelSearch'" [label]="languageService.getItem(14)" [statusLabel]="2" [(model)]="template.LevelId" [display]="template.LevelName" [optional]="true"></swe-element>
        <span class="small" *ngIf="template.LevelPath&&template.LevelPath.length>0"><i>({{template.LevelPath}})</i></span>
      </div>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(1081)" [statusLabel]="2" [(model)]="template.Type" [items]="types"></swe-element>
      <swe-card [header]="languageService.getItem(492)" [color]="'swe'" [open]="false" [hasCollapse]="true" [hasRefresh]="false">
        <swe-element [type]="'System.Date'" [label]="languageService.getItem(396)" [statusLabel]="2" [(model)]="template.Start"></swe-element>
        <swe-element [type]="'System.Date'" [label]="languageService.getItem(397)" [statusLabel]="2" [(model)]="template.End"></swe-element>
        <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(988)" [statusLabel]="2" [(model)]="template.ShowOverview"></swe-element>
        <swe-element [type]="'System.List'" [label]="languageService.getItem(1378)" [statusLabel]="2" [(model)]="template.ParentId" [items]="parents" [optional]="true" [optionalValue]="0"></swe-element>
        <swe-card [header]="languageService.getItem(1373)" [color]="'swe'" [open]="true" [hasCollapse]="false" [hasRefresh]="false" [hasSave]="true" (saveChange)="save()">
          <div class="row py-2">
            <div class="col-12 col-md-6">
              <swe-element [type]="'System.List'" [label]="languageService.getItem(1374)" [statusLabel]="2" [(model)]="template.CalcDayStart" [items]="calcweekdays" [optional]="true" [optionalValue]="-1"></swe-element>
              <swe-element [type]="'System.Int32'" [label]="languageService.getItem(1376)" [statusLabel]="2" [(model)]="template.CalcNoDays"></swe-element>
              <swe-element [type]="'System.List'" [label]="languageService.getItem(1377)" [statusLabel]="2" [(model)]="template.MidnightOption" [items]="midnightoptions" [optional]="true" [optionalValue]="0"></swe-element>
              <swe-element [type]="'System.Double'" [label]="languageService.getItem(1386)" [statusLabel]="2" [(model)]="template.FullTime"></swe-element>
              <swe-element [type]="'System.Double'" [label]="languageService.getItem(1387)" [statusLabel]="2" [(model)]="template.Admin"></swe-element>
            </div>
            <div class="col-12 col-md-6">
              <swe-element [type]="'System.Int32'" [label]="languageService.getItem(1382)" [statusLabel]="2" [(model)]="template.NoShifts" [disabled]="true"></swe-element>
              <swe-element [type]="'System.Double'" [label]="languageService.getItem(1286)" [statusLabel]="2" [(model)]="template.NoHours" [disabled]="true"></swe-element>
              <swe-element [type]="'System.Int32'" [label]="languageService.getItem(1383)" [statusLabel]="2" [(model)]="template.OffDuty" [disabled]="true"></swe-element>
              <swe-element [type]="'System.Int32'" [label]="languageService.getItem(1384)" [statusLabel]="2" [(model)]="template.Vacation" [disabled]="true"></swe-element>
              <swe-element [type]="'System.Double'" [label]="languageService.getItem(1388)" [statusLabel]="2" [(model)]="template.EmploymentRate" [disabled]="true"></swe-element>
            </div>
          </div>
        </swe-card>
      </swe-card>
      <label>
        <span>{{languageService.getItem(6)}}</span>
      </label>
      <div class="table-responsive">
        <table class="table table-sm table-bordered">
          <thead>
            <tr>
              <th>{{languageService.getItem(480)}}</th>
              <th>{{languageService.getItem(10)}}</th>
              <th>{{languageService.getItem(722)}}</th>
              <th>{{languageService.getItem(623)}}</th>
              <th>{{languageService.getItem(1363)}}</th>
              <th>{{languageService.getItem(1364)}}</th>
              <th>{{languageService.getItem(11)}}</th>
              <th>{{languageService.getItem(1012)}}</th>
              <th>{{languageService.getItem(1013)}}</th>
              <th>{{languageService.getItem(355)}}</th>
              <th>{{languageService.getItem(1014)}}</th>
              <th>{{languageService.getItem(1015)}}</th>
              <th>{{languageService.getItem(1016)}}</th>
              <th>{{languageService.getItem(1017)}}</th>
              <th>{{languageService.getItem(1018)}}</th>
              <th>{{languageService.getItem(156)}}</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let setting of template.Settings" [ngClass]="{'bg-danger text-light': setting.IsDeleted, 'bg-warning': setting.IsError,'bg-success text-light': setting.IsNew, 'swe-disabled-block': !setting.IsActive}">
              <td style="width:150px;"><swe-element [type]="'System.String'" [label]="languageService.getItem(480)" [statusLabel]="0" [(model)]="setting.Name" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td><swe-element [type]="'System.Time'" [label]="languageService.getItem(10)" [statusLabel]="0" [(model)]="setting.Start" [format]="'HH:mm'" (modelChange)="manageEnd(setting)" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td style="width:75px;"><swe-element [type]="'System.Int32'" [container]="{min:0}" [label]="languageService.getItem(722)" [statusLabel]="0" [(model)]="setting.Hours" (modelChange)="manageEnd(setting)" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td style="width:75px;"><swe-element [type]="'System.Int32'" [container]="{min:0}" [label]="languageService.getItem(623)" [statusLabel]="0" [(model)]="setting.Minutes" (modelChange)="manageEnd(setting)" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td><swe-element [type]="'System.Time'" [label]="languageService.getItem(1363)" [statusLabel]="0" [(model)]="setting.BreakStart" [format]="'HH:mm'" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td style="width:75px;"><swe-element [type]="'System.Int32'" [container]="{min:0}" [label]="languageService.getItem(1364)" [statusLabel]="0" [(model)]="setting.Break" (modelChange)="manageEnd(setting)" [access]="setting.IsEdit?3:1" *ngIf="setting.IsEdit||setting.Break>0"></swe-element></td>
              <td><swe-element [type]="'System.Time'" [label]="languageService.getItem(11)" [statusLabel]="0" [model]="setting.End" [disabled]="true" [format]="'HH:mm'" [access]="setting.IsEdit?3:1"></swe-element></td>

              <td style="width:150px;"><swe-element [type]="'System.MultiList'" [label]="languageService.getItem(1012)" [statusLabel]="0" [(model)]="setting.Weekdays" [items]="weekdays" [container]="{isbit:true}" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td style="width:75px;"><swe-element [type]="'System.Int32'" [label]="languageService.getItem(1013)" [statusLabel]="0" [(model)]="setting.WeekNr" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td><swe-element [type]="'System.List'" [label]="languageService.getItem(355)" [statusLabel]="0" [(model)]="setting.Iteration" [items]="iterations" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td style="width:75px;"><swe-element [type]="'System.Int32'" [label]="languageService.getItem(1014)" [statusLabel]="0" [(model)]="setting.Quantity" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td style="width:75px;"><swe-element [type]="'System.Int32'" [label]="languageService.getItem(1015)" [statusLabel]="0" [(model)]="setting.Min" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td style="width:75px;"><swe-element [type]="'System.Int32'" [label]="languageService.getItem(1016)" [statusLabel]="0" [(model)]="setting.Max" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td><swe-element [type]="'System.List'" [label]="languageService.getItem(1017)" [statusLabel]="0" [(model)]="setting.TimeType" [items]="timetypes" [optional]="true" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td><swe-element [type]="'System.List'" [label]="languageService.getItem(1018)" [statusLabel]="0" [(model)]="setting.Activity" [items]="generalService.activitytypes" [optional]="true" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td>
                <div class="table-responsive">
                  <table class="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th [ngClass]="{'text-light': setting.IsNew || setting.IsDeleted}">{{languageService.getItem(1020)}}</th>
                        <th [ngClass]="{'text-light': setting.IsNew || setting.IsDeleted}">{{languageService.getItem(1021)}}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let bookingprofile of setting.Properties; index as i" [ngClass]="{'bg-danger text-light': bookingprofile.IsDeleted, 'bg-success text-light': setting.IsNew}">
                        <td><swe-element [type]="'System.List'" [label]="languageService.getItem(1020)" [statusLabel]="0" [(model)]="bookingprofile.PropertyId" (modelChange)="propertyChosen($event, setting, i)" [items]="properties" [access]="bookingprofile.IsEdit?3:1"></swe-element></td>
                        <td><swe-element [(model)]="bookingprofile.Value" [label]="languageService.getItem(1021)" [type]="bookingprofile.Type" [items]="manageReload(bookingprofile)" [access]="bookingprofile.IsEdit?3:1"></swe-element></td>
                        <td>
                          <a href="javascript:void(0)" class="swe-no-link me-2" (click)="deleteBookingProfile(setting, bookingprofile)" *ngIf="bookingprofile.IsEdit"><i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-trash-fill': !bookingprofile.IsDeleted, 'bi-arrow-counterclockwise': bookingprofile.IsDeleted}"></i></a>
                          <a href="javascript:void(0)" class="swe-no-link" (click)="bookingprofile.IsEdit=!bookingprofile.IsEdit"><i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-pencil-square': !bookingprofile.IsEdit, 'bi-x-lg': bookingprofile.IsEdit}"></i></a>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="3"><a href="javascript:void(0)" class="swe-no-link" (click)="addBookingProfile(setting)" [ngClass]="{'text-light': setting.IsNew || setting.IsDeleted}"><i class="bi bi-swe-fw bi-swe bi-plus-square-fill"></i>{{languageService.getItem(100)}}</a></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </td>
              <td>
                <a href="javascript:void(0)" class="swe-no-link me-2" (click)="deleteSetting(setting)" [attr.title]="languageService.getItem(1300)"><i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-trash-fill': !setting.IsDeleted, 'bi-arrow-counterclockwise': setting.IsDeleted}"></i></a>
                <a href="javascript:void(0)" class="swe-no-link me-2" (click)="addSetting(setting)" [attr.title]="languageService.getItem(1298)"><i class="bi bi-swe-fw bi-swe bi-copy"></i></a>
                <a href="javascript:void(0)" class="swe-no-link" (click)="setting.IsEdit=!setting.IsEdit" [attr.title]="!setting.IsEdit ? languageService.getItem(1299):languageService.getItem(1301)"><i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-pencil-square': !setting.IsEdit, 'bi-x-lg': setting.IsEdit}"></i></a>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="15"><a href="javascript:void(0)" class="swe-no-link" (click)="addSetting()"><i class="bi bi-swe-fw bi-swe bi-plus-square-fill"></i>{{languageService.getItem(100)}}</a></td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="save()" *ngIf="(permissionService.permissions.Templates>1)"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
        <button class="btn btn-primary" (click)="copy()" *ngIf="id>0&&(permissionService.permissions.Templates>1)"><i class="bi bi-journals"></i>&nbsp;{{languageService.getItem(477)}}</button>
        <button class="btn btn-primary" (click)="connect()" *ngIf="id>0&&(permissionService.permissions.Templates>1)"><i class="bi bi-link"></i>&nbsp;{{languageService.getItem(1379)}}</button>
        <button class="btn btn-danger" (click)="delete()" *ngIf="id>0&&(permissionService.permissions.Templates>2)"><i class="bi bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
      </div>
    </swe-card>
  </div>
</div>
