<swe-card header="{{languageService.getItem(75)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false" [rulelist]="['SweHasShortcuts']">
  <div class="d-grid gap-2">
    <swe-adminrule [rulelist]="['SweHasBookings']"></swe-adminrule>
    <ng-container *ngIf="permissionService.permissions.Bookings>1">
      <button class="btn btn-primary" (click)="goto(1)"><i class="bi bi-calendar-date"></i>&nbsp;{{languageService.getItem(63)}}</button>
    </ng-container>
    <swe-adminrule [rulelist]="['SweHasUserMarkedDates']"></swe-adminrule>
    <ng-container *ngIf="permissionService.permissions.MarkedDates>1">
      <button class="btn btn-primary" (click)="goto(2)"><i class="bi bi-calendar-check"></i>&nbsp;{{languageService.getItem(537)}}</button>
    </ng-container>
    <swe-adminrule [rulelist]="['SweHasUsers']"></swe-adminrule>
    <ng-container *ngIf="permissionService.permissions.Users>1">
      <button class="btn btn-primary" (click)="goto(3)"><i class="bi bi-people-fill"></i>&nbsp;{{languageService.getItem(62)}}</button>
    </ng-container>
    <swe-adminrule [rulelist]="['SweHasLevels']"></swe-adminrule>
    <ng-container *ngIf="permissionService.permissions.Levels>1">
      <button class="btn btn-primary" (click)="goto(4)"><i class="bi bi-diagram-3-fill"></i>&nbsp;{{languageService.getItem(64)}}</button>
    </ng-container>
    <swe-adminrule [rulelist]="['SweCreateNewTimereport']"></swe-adminrule>
    <ng-container *ngIf="false&&permissionService.permissions.NewTimereport>0">
      <button class="btn btn-primary" (click)="goto(5)"><i class="bi bi-clock-fill"></i>&nbsp;{{languageService.getItem(1041)}}</button>
    </ng-container>
    <swe-adminrule [rulelist]="['SweCreateNewAbscentTimereport']"></swe-adminrule>
    <ng-container *ngIf="false&&permissionService.permissions.NewAbscentTimereport>0">
      <button class="btn btn-primary" (click)="goto(6)"><i class="bi bi-person-x-fill"></i>&nbsp;{{languageService.getItem(1042)}}</button>
    </ng-container>
  </div>
</swe-card>
