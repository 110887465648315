<swe-card header="{{languageService.getItem(38)}}" *ngIf="level" [(open)]="open" (refreshChange)="load()" [hasEditMode]="id>0" [focus]="true" (saveChange)="save()" [hasSave]="level.Access>1" icon="bi-diagram-3-fill">
  <span swe-rightcommand>
  </span>
  <!--LevelName-->
  <div *ngIf="!loading&&level&&level.NameAccess>0" class="row">
    <swe-element [statusLabel]="1" [(model)]="level.Name" [access]="editmode?level.NameAccess:1" [label]="languageService.getItem(480)" [type]="'System.String'"></swe-element>
  </div>
  <!--LevelGroup-->
  <div *ngIf="!loading&&permissionService.permissions.LevelAdmin" class="row">
    <swe-adminrule [rulelist]="['SweLevelGroups']"></swe-adminrule>
    <swe-element [statusLabel]="1" [optional]="true" [(model)]="level.Group" (modelChange)="groupChosen($event)" [access]="editmode?3:1" [label]="languageService.getItem(236)" [items]="generalService.levelgroups" [type]="'System.List'"></swe-element>
  </div>
  <!--Parent-->
  <div *ngIf="!loading&&permissionService.permissions.LevelAdmin" class="row">
    <swe-adminrule [rulelist]="['SweLevelAdmin']"></swe-adminrule>
    <swe-element [statusLabel]="1" [(model)]="level.ParentId" (modelChange)="loadParentProfile()" [access]="editmode?3:1" [display]="level.Parent" [label]="languageService.getItem(235)" [optional]="true" [type]="'System.LevelSearch'"></swe-element>
  </div>
  <!--Profile-->
  <div *ngIf="!loading" class="row">
    <swe-profile [(model)]="level.Profile" (modelChange)="profiledone()" [id]="id" [rulelist]="['SweLevelProfile: Read']" [hasEditMode]="true" [editMode]="editmode"></swe-profile>
  </div>
  <!--Admin-->
  <swe-card [header]="languageService.getItem(112)" [color]="'swe'" [open]="id==0?true:false" [hasRefresh]="false" *ngIf="!loading&&((level.ExternalAccess>0||level.ContractAccess>0)||(id == 0 && permissionService.permissions.SweLevelContracts > 1 || permissionService.permissions.ExternalCategories > 1))" [rulelist]="['SweLevelContract', 'SweExternalUserEmployments']">
    <swe-element [statusLabel]="1" [optional]="true" [(model)]="level.ExternalCategories" [access]="editmode?3:1" [container]="{max:7}" [label]="languageService.getItem(517)" [items]="employmentcategories" [disabled]="id!=0&&level.ExternalAccess<2" [type]="'System.FilteredCheckboxList'" *ngIf="(id==0&&permissionService.permissions.ExternalCategories > 1) || level.ExternalAccess>0"></swe-element>
    <swe-element [statusLabel]="1" [optional]="true" [(model)]="level.ContractId" [access]="editmode?3:1" [label]="languageService.getItem(953)" [items]="contracts" [type]="'System.ContractSearch'" *ngIf="(id==0||level.ContractId==0)&&permissionService.permissions.SweLevelContracts > 1"></swe-element>
    <a *ngIf="level.ContractAccess>1&&editmode&&level.ContractId!=0" href="javascript:void(0);" class="swe-no-link" (click)="contractEdit=true"><i class="bi bi-swe-pull-right swe-click bi-pencil-fill" *ngIf="!contractEdit"></i></a>
    <div *ngIf="id!=0 && level.ContractAccess>1&&level.ContractId!=0">
      <div class="row mb-3" *ngIf="!contractEdit||!editmode">
        <label class="col-{{this.permissionService.permissions.ProfileLabel}}">{{languageService.getItem(953)}}</label>
        <div class="col-{{12 - this.permissionService.permissions.ProfileLabel}}">
          <a [routerLink]="'/admin/contracts/'+level.ContractId" *ngIf="permissionService.permissions.Contract > 0">{{contractName}}</a>
          <span *ngIf="permissionService.permissions.Contract < 1">{{contractName}}</span>
        </div>
      </div>
      <div style="display:table;margin-left:auto;margin-right:0;">
        <a *ngIf="level.ContractAccess>1&&editmode" href="javascript:void(0);" class="swe-no-link" (click)="contractEdit=false"><i class="bi swe-click bi-x-lg me-2" *ngIf="contractEdit"></i></a>
        <a *ngIf="level.ContractAccess>1&&contractEdit" href="javascript:void(0);" class="swe-no-link" (click)="level.ContractId=0"><i class="bi bi-trash-fill swe-click"></i></a>
      </div>
      <swe-element [statusLabel]="1" [optional]="true" [label]="languageService.getItem(953)" [(model)]="level.ContractId" [display]="contractName" (modelChange)="contractEdit=false" [access]="editmode?3:1" [items]="contracts" [type]="'System.ContractSearch'" [disabled]="level.ContractAccess<2" *ngIf="contractEdit&&editmode"></swe-element>
    </div>
  </swe-card>
  <!--Remind SMS-->
  <div *ngIf="!loading&&level.RemindSmsAccess">
    <swe-element [statusLabel]="1" type="System.Tristate" [(model)]="level.RemindSmsDefault" [label]="languageService.getItem(538)" [access]="editmode?3:1" [container]="{markChanges: true, optionLanguages: { checked: 891, unchecked: 892, indeterminated: 1359}}"></swe-element>
  </div>
  <!--Buttons-->
  <div class="d-grid gap-2" *ngIf="!loading">
    <swe-adminrule [rulelist]="['SweLevelProfile: Write']"></swe-adminrule>
    <button class="btn btn-primary" (click)="save()" *ngIf="level.Access>1&&editmode" #sweSaveElement>{{languageService.getItem(16)}}</button>
    <swe-adminrule [rulelist]="['SweLevelConnect']"></swe-adminrule>
    <button class="btn btn-success" (click)="connect()" *ngIf="level.ConnectAccess>1&&!level.IsConnected">{{languageService.getItem(584)}}</button>
    <button class="btn btn-success" (click)="disconnect()" *ngIf="level.ConnectAccess>2&&level.IsConnected">{{languageService.getItem(585)}}</button>
    <swe-adminrule [rulelist]="['SweLevelProfile: Delete']"></swe-adminrule>
    <swe-confirm *ngIf="id>0&&level.Access>2&&editmode" [body]="languageService.getItem(193)" (acceptChange)="delete()">
      <button class="btn btn-danger">{{languageService.getItem(17)}}</button>
    </swe-confirm>
  </div>
</swe-card>
