<swe-card [header]="header.length>0?header:languageService.getItem(1234)" [(open)]="open" [color]="cardcolor" [hasCollapse]="true" [hasRefresh]="true" (refreshChange)="load()" (openChange)="load()" *ngIf="(salaryaccess>0)||(invoiceaccess>0)">
  <swe-datepager [type]="'booking'" (dateChange)="load()" [disabled]="isloading" [hideTime]="true" *ngIf="isoverview"></swe-datepager>
  <swe-filteritems *ngIf="isoverview"></swe-filteritems>
  <swe-table [data]="articles" [loading]="isloading">
    <ng-container *ngFor="let groupby of groupedby | keyvalue">
      <table class="table" *ngIf="(groupby.key==0&&salaryaccess>0)||(groupby.key==1&&invoiceaccess>0)">
        <thead>
          <tr class="bg-light">
            <th colspan="10">{{groupby.value.header}}</th>
          </tr>
          <tr>
            <th>{{languageService.getItem(1235)}}</th>
            <th>{{languageService.getItem(1236)}}</th>
            <th>{{languageService.getItem(1244)}}</th>
            <th>{{languageService.getItem(1245)}}</th>
            <th>{{groupby.key==0 ? languageService.getItem(901) : ''}}</th>
            <th [ngClass]="{'swe-table-marker-top swe-table-marker-left': groupby.key==0}">{{groupby.key==0 ? languageService.getItem(1365) : ''}}</th>
            <th [ngClass]="{'swe-table-marker-top': groupby.key==0}">{{groupby.key==0 ? languageService.getItem(1366) : ''}}</th>
            <th [ngClass]="{'swe-table-marker-top': groupby.key==0}">{{groupby.key==0 ? languageService.getItem(1367) : ''}}</th>
            <th [ngClass]="{'swe-table-marker-top swe-table-marker-right': groupby.key==0}">{{groupby.key==0 ? languageService.getItem(1368) : ''}}</th>
            <th>{{groupby.key==0 ? languageService.getItem(1272) : languageService.getItem(901)}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let article of groupby.value.items">
            <td style="width:19%;">{{article.Name}}</td>
            <td style="width:9%;">{{article.PayType}}</td>
            <td style="width:9%;">{{article.Quantity}}</td>
            <td style="width:9%;">{{article.Price}}</td>
            <td style="width:9%;">{{groupby.key==0 ? article.Sum : ''}}</td>
            <td [ngClass]="{'swe-table-marker-left': groupby.key==0}" style="width:9%;">{{groupby.key==0 ? article.ExtraEmployerFee : ''}}</td>
            <td style="width:9%;">{{groupby.key==0 ? article.ExtraVacationPercentage : ''}}</td>
            <td style="width:9%;">{{groupby.key==0 ? article.ExtraPensionPercentage : ''}}</td>
            <td [ngClass]="{'swe-table-marker-right': groupby.key==0}" style="width:9%;">{{groupby.key==0 ? article.ExtraGeneralPercentage : ''}}</td>
            <td style="width:9%;">{{article.Total}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th style="text-align:right;" colspan="5">&nbsp;</th>
            <th [ngClass]="{'swe-table-marker-left swe-table-marker-right swe-table-marker-bottom': groupby.key==0}" style="text-align:right;" colspan="4">&nbsp;</th>
            <th>{{groupby.key==0 ? sumsalary : suminvoice}}</th>
          </tr>
        </tfoot>
      </table>
    </ng-container>
    <table class="table table-borderless" *ngIf="articles&&articles.length>0">
      <thead>
        <tr class="bg-light">
          <th colspan="8">{{languageService.getItem(1282)}}</th>
          <th colspan="2">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="width:19%;">&nbsp;</td>
          <td style="width:9%;">&nbsp;</td>
          <td style="width:9%;">&nbsp;</td>
          <td style="width:9%;">&nbsp;</td>
          <td style="width:9%;">&nbsp;</td>
          <td style="width:9%;">&nbsp;</td>
          <td style="width:9%;">&nbsp;</td>
          <td style="width:9%;">&nbsp;</td>
          <td style="width:9%;"><b>{{languageService.getItem(1272)}}</b></td>
          <td style="width:9%;">{{sumsalary}}</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td><b>{{languageService.getItem(1277)}}</b></td>
          <td>{{suminvoice}}</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td><b>{{languageService.getItem(1278)}}</b></td>
          <td>{{tb}}</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td><b>{{languageService.getItem(1279)}}</b></td>
          <td>{{tg}} %</td>
        </tr>
      </tbody>
    </table>
  </swe-table>
</swe-card>
