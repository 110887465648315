<div class="card shadow mb-2 {{bordercolor}}" [ngStyle]="{'width': (fitcontent ? 'max-content' : 'unset'), 'min-width': (fitcontent ? '100%' : 'unset')}">
  <div class="card-body p-md-3">
    <swe-adminrule *ngIf="rulelist" [rulelist]="rulelist"></swe-adminrule>
    <h2 class="card-title" [ngClass]="{'mb-0':!isOpen, 'swe-click': hasCollapse}" (click)="hasCollapse&&toggle()">
      <i class="bi {{icon}} me-3" *ngIf="icon"></i>
      <ng-content select="[swe-leftcommand]"></ng-content>
      <span [ngClass]="{'swe-big-header': bigheader, 'd-md-none':hideHeaderOnDesktop&&headerDetail}">{{header}}&nbsp;</span>
      <span [ngClass]="{'swe-big-header':bigheader}" *ngIf="headerDetail" class="d-none d-md-inline">{{headerDetail}}&nbsp;</span>
      <ng-content select="[swe-hits]"></ng-content>
      <span class="swe-print-remove" *ngIf="hasEditMode&&isOpen"><a href="javascript:void(0);" class="swe-no-link" (click)="changeMode($event)" #sweFocusElement1><i class="bi bi-swe-fw bi-pencil-fill" [ngClass]="{'text-info': editMode}"></i></a></span>
      <span class="swe-print-remove" *ngIf="hasCollapse"><a href="javascript:void(0);" class="swe-no-link float-end" [attr.title]="header" #sweFocusElement2><i class="bi bi-swe-fw bi-swe-pull-right bi-swe-xs-pull-right" [ngClass]="{'bi-chevron-right': !isOpen,'bi-chevron-down': isOpen}"></i></a></span>
      <span class="swe-print-remove" *ngIf="hasClose&&isOpen" (click)="close($event)"><i class="bi bi-swe-fw bi-x-lg bi-swe-pull-right bi-swe-xs-pull-right swe-click"></i></span>
      <span class="swe-print-remove" *ngIf="hasRefresh&&isOpen" (click)="refresh($event)"><i class="bi bi-swe-fw bi-arrow-repeat bi-swe-pull-right bi-swe-xs-pull-right swe-click" [ngClass]="{'bi-swe-spin': isRefreshing}" [attr.title]="languageService.getItem(995)"></i></span>
      <span class="swe-print-remove" *ngIf="hasSave&&isOpen&&editMode" (click)="save($event)"><i class="bi bi-swe-fw bi-floppy2-fill bi-swe-pull-right bi-swe-xs-pull-right swe-click" [attr.title]="languageService.getItem(16)"></i></span>
      <ng-content select="[swe-rightcommand]" *ngIf="isOpen"></ng-content>
      <span class="swe-print-remove bi bi-swe-pull-right swe-click d-none d-md-block" *ngIf="hasZoom&&isOpen" click="stopPropagation($event)">
        <input type="range" min=".3" [max]="maxZoom" step=".01" [(ngModel)]="zoomInput" (change)="zoomChanged($event)" class="form-range" />
      </span>
      <span style="font-size:14px;font-style:italic;font-weight:400;" *ngIf="headerDetail" class="d-block d-md-none mt-1">{{headerDetail}}&nbsp;</span>
    </h2>
    <div class="bg-transparent" *ngIf="isOpen">
      <div class="btn-group mb-1" *ngIf="tabs.length>1">
        <button *ngFor="let tab of tabs" class="btn" [ngClass]="{'btn-primary': tab.id==active, 'btn-outline-primary': tab.id!=active}" (click)="change(tab.id,$event)">{{tab.name}}</button>
      </div>
      <ng-content></ng-content>
      <div *ngIf="hasZoom">
        <div [style.transform]="'scale(' + zoom + ')'" [style.transform-origin]="'0 0'" [style]="{'width': 100/zoom + '%'}" [style.transition]="'transform .02s ease, width .01s ease'">
          <ng-content select="[zoomContent]">
          </ng-content>
        </div>
      </div>
      <ng-content select="[afterZoom]"></ng-content>
    </div>
  </div>
</div>
