<div class="d-none d-md-inline-block align-top">
  <!--Visible for medium and larger devices-->
  <swe-datepicker [changed]="startChanged" [(model)]="startWithoutTime" (modelChange)="startHasChanged()" [label]="labelStart" *ngIf="!hideDate&&wholeday" [disabled]="disabled" [alwaysshowcontents]="alwaysshowcontents"></swe-datepicker>
  <swe-datepicker [changed]="startChanged" [(model)]="startWithTime" (modelChange)="startHasChanged()" [label]="labelStart" *ngIf="!hideDate&&!wholeday" [disabled]="disabled" [alwaysshowcontents]="alwaysshowcontents"></swe-datepicker>
  <swe-timepicker [changed]="startChanged" [(model)]="startWithTime" (modelChange)="startHasChanged()" [label]="labelStart" *ngIf="!hideTime&&!wholeday" [disabled]="disabled" [hideSeconds]="hideSeconds"></swe-timepicker>
  <div class="d-inline-block align-middle p-1" *ngIf="!hideEnd">-</div>
  <swe-timepicker [changed]="endChanged" [(model)]="endWithTime" (modelChange)="endHasChanged()" [label]="labelEnd" *ngIf="!hideEnd&&!hideTime&&!wholeday" [disabled]="disabled" [hideSeconds]="hideSeconds"></swe-timepicker>
  <swe-datepicker [changed]="endChanged" [(model)]="endWithTime" (modelChange)="endHasChanged()" [label]="labelEnd" *ngIf="!hideEnd&&!hideDate&&!wholeday" [disabled]="disabled" [alwaysshowcontents]="alwaysshowcontents"></swe-datepicker>
  <swe-datepicker [changed]="endChanged" [(model)]="endWithoutTime" (modelChange)="endHasChanged()" [label]="labelEnd" *ngIf="!hideEnd&&!hideDate&&wholeday" [disabled]="disabled" [alwaysshowcontents]="alwaysshowcontents"></swe-datepicker>
  <div class="d-inline-block align-middle p-1 ms-2" *ngIf="!hideEnd&&!hideTime&&!hideDate&&!disabled&&(permissionService.permissions.HasFullday||useWholeDay)"><swe-element [(model)]="wholeday" (modelChange)="wholedayHasChanged()" [bottomMargin]="0" [label]="languageService.getItem(1070)" [statusLabel]="4" [type]="'System.Boolean'"></swe-element></div>
  <div class="d-inline-block align-middle p-1" *ngIf="hideEnd&&!disabled&&!hideReset"><i class="bi bi-trash-fill swe-click" (click)="reset()"></i></div>
</div>
<div class="d-inline-block d-md-none align-top">
  <!--Visible for x-small and small devices-->
  <div class="d-flex flex-row">
    <input [attr.aria-label]="labelStart" type="datetime-local" class="form-control col-11" [ngClass]="{'bg-warning': startChanged}" [(ngModel)]="startAsISO" (ngModelChange)="startHasChanged()" *ngIf="!hideDate&&!hideTime" [disabled]="disabled" />
    <input [attr.aria-label]="labelStart" type="date" class="form-control col-11" [ngClass]="{'bg-warning': startChanged}" [(ngModel)]="startAsDateString" (ngModelChange)="startHasChanged()" *ngIf="!hideDate&&hideTime" [disabled]="disabled" />
    <input [attr.aria-label]="labelStart" type="time" class="form-control col-11" [ngClass]="{'bg-warning': startChanged}" [(ngModel)]="startAsTimeString" (ngModelChange)="startHasChanged()" *ngIf="!hideTime&&hideDate" [disabled]="disabled" />
    <span class="d-inline-block align-middle p-1" *ngIf="!hideEnd">-</span>
  </div>
  <div *ngIf="!hideEnd" class="mt-3 input-group">
    <input [attr.aria-label]="labelEnd" type="datetime-local" class="form-control" [ngClass]="{'bg-warning': endChanged}" [(ngModel)]="endAsISO" (ngModelChange)="endHasChanged()" *ngIf="!hideDate&&!hideTime" [disabled]="disabled" />
    <input [attr.aria-label]="labelEnd" type="date" class="form-control" [ngClass]="{'bg-warning': endChanged}" [(ngModel)]="endAsDateString" (ngModelChange)="endHasChanged()" *ngIf="!hideDate&&hideTime" [disabled]="disabled" />
    <input [attr.aria-label]="labelEnd" type="time" class="form-control" [ngClass]="{'bg-warning': endChanged}" [(ngModel)]="endAsTimeString" (ngModelChange)="endHasChanged()" *ngIf="!hideTime&&hideDate" [disabled]="disabled" />
  </div>
</div>
