import { Component, Input, OnChanges, OnInit, SecurityContext } from '@angular/core';

import { LanguageService } from '../../../_services/language.service';
import { PermissionService } from '../../../_services/permission.service';
import { DataService } from '../../../_services/data.service';
import { AlertService } from '../../../_services/alert.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { GeneralService } from '../../../_services/general.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SettingService } from '../../../_services/setting.service';




@Component({
  selector: 'swe-guidedata',
  templateUrl: './guidedata.component.html'
})
export class GuideDataComponent implements OnInit {
  @Input() id: number;
  @Input() systemAdmin: boolean = false;

  private _isLoading: boolean = false;
  private _guide: any;
  private _open: boolean = true; 
  private _ruleKeywords: any[] = [];
  private _selectedRules: any[] = [{ RuleId: -1, Access: -1 }];
  private _guideKeywords: any[] = [];
  private _headers: any[] = [
    { Id: 1, Name: 'H1' },
    { Id: 2, Name: 'H2' },
    { Id: 3, Name: 'H3' },
    { Id: 4, Name: 'H4' },
    { Id: 5, Name: 'H5' },
    { Id: 6, Name: 'H6' }
  ];
  private _baseUrl: string = '/api/v1/guides/';
  private _lock: boolean = true;


  public get languages() {
    return [
      { Id: 'sv', Name: this.languageService.getItem(1397) },
      { Id: 'en', Name: this.languageService.getItem(1398) },
    ]
  }
  public get open() {
    return this._open;
  }
  public set open(val) {
    this._open = val;
  }
  public get guide() {
    return this._guide;
  }
  public get lock() {
    return this._lock;
  }
  public set lock(val) {
    this._lock = val;
  }
  public get locked() {
    return (this.systemAdmin && this._lock) && this.id != 0;
  }
  public set guide(value) {
    this._guide = value;
  }
  public get isLoading() {
    return this._isLoading;
  }
  public get ruleKeywords() {
    return this._ruleKeywords;
  }
  public get editmode() {
    return this.id > 0 ? this.settingService.editMode && this.permissionService.permissions.GuideAccess > 1 : true;
  }
  public get guideKeywords() {
    return this._guideKeywords;
  }

  public get headers() {
    return this._headers;
  }

  public get access() {
    return [
      { Id: 1, Name: 'Read' },
      { Id: 2, Name: 'Write' },
      { Id: 3, Name: 'Delete' }
    ]
  }

  public get selectedRules() {
    return this._selectedRules;
  }
  constructor(
    public alertService: AlertService,
    public dataService: DataService,
    public generalService: GeneralService,
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public settingService: SettingService,
    private location: Location
  ) { }



  ngOnInit() {
    if (this.systemAdmin) {
      this._baseUrl = '/api/v1/guides/base/';
    }
    if (this.permissionService.permissions.GuideAccess == 0) {
      return;
    }
    this.load();
    this.loadRuleKeywords();
  }

  public save() {
    const verb = this.id > 0 ? 'Put' : 'Post';
    const path = this.id > 0 ? `${this._baseUrl}${this.id}` : this._baseUrl;
    for (let i = 0; i < this._guide.Contents.length; i++) {
      this._guide.Contents[i].Body = this.sanitizer.sanitize(1, this._guide.Contents[i].Body);
    }
    this._guide.Rules = this.parseRules();
    this.dataService.tokenRequest(path, verb, this._guide, 'text', 'response')
      .subscribe((res) => {
        if (res) {

          this.alertService.Add({ type: 'success', message: res.body });

          if (res.status == 201) {
            this.router.navigate([res.headers.get('Location')], { replaceUrl: true });
          }
          else {
            this.load();
          }
        }

      });
  }

  public load() {
    if (this.permissionService.permissions.GuideAccess < 1) {
      return;
    }
    this._isLoading = true;
    if (this.id == 0) {
      this._guide = {
        Name: '',
        Culture: 'sv',
        Keywords: 0,
        Contents: [{ Body: '', SortOrder: 0, Header: '' }],
        Roles: '',
        Rules: '',
        IsActive: true
      };
      this._isLoading = false;
      return;
    }
    this._selectedRules = [{ RuleId: -1, Access: -1 }];
    this.dataService.tokenRequest(this._baseUrl + this.id, 'GET').subscribe((res) => {
      this._guide = res;
      console.log(this._guide);
      console.log(this.generalService.roles)
      if (res.Rules) {
        this.serializeRules();
      }
      this._isLoading = false;
    });
  }

  public insertBold(content) {
    if (!content.BoldContent)
    {
      return;
    }
    const textArea = document.getElementById('content-' + content.SortOrder) as HTMLTextAreaElement;
    this.insertTextAtCursor(textArea, `{{Bold(${content.BoldContent})}}`, content);
    content.BoldContent = '';
    content.ShowBoldMenu = false;
  }

  public insertVideo(content) {
    if (!content.VideoId) {
      return;
    }
    const textArea = document.getElementById('content-' + content.SortOrder) as HTMLTextAreaElement;
    this.insertTextAtCursor(textArea, `{{Video(${content.VideoId})}}`, content);

    content.VideoId = '';
    content.ShowVideoMenu = false;
  }
  public saveAccess() {
    const access = {
      Rules: this.parseRules(),
      Roles: this._guide.Roles,
      GuideId: this.id,
      IsActive: this._guide.IsActive
    };
    this.dataService.tokenRequest(this._baseUrl + 'access', 'Post', access, 'text').subscribe(res => {
      this.alertService.Add({ type: 'success', message: res });
    })
  }
  public insertHeader(size:number, content: any)
  {
    if (!content.Header) {
      content.ShowHeaderMenu = false;
      return;
    }
    const textArea = document.getElementById('content-' + content.SortOrder) as HTMLTextAreaElement;
    this.insertTextAtCursor(textArea, `{{Header(${content.Header}, ${size})}}`, content)
    content.Header = '';
    content.ShowHeaderMenu = false;
  }

  public delete() {

    this.dataService.tokenRequest(this._baseUrl + this.id, 'DELETE', {}, 'text')
      .subscribe((res) => {
        if (res) {

          this.alertService.Add({ type: 'success', message: res });

          this.location.back();
        }
      });
  };

  public addRule() {
    const rule = this._selectedRules.shift();
    this._selectedRules.unshift([{ RuleId: -1, Access: -1 }]);
    if (rule.RuleId == -1 || rule.Access == -1) {
      return;
    }
    let existingRule = this._selectedRules.find(x => x.RuleId == rule.RuleId);

    if (existingRule) {
      if (existingRule.Access < rule.Access) {
        existingRule.Access = rule.Access;
      }
      return;
    }
    this._selectedRules.push({ RuleId: rule.RuleId, Access: rule.Access });
  }

  public removeRule(index: number) {
    this._selectedRules.splice(index, 1);
  }

  public addContent(e) {
    e.stopPropagation();
    this._guide.Contents.push({ Body: '', SortOrder: this._guide.Contents.length });
  }

  private parseRules() {
    let res = '';
    this._selectedRules.shift();
    this._selectedRules.forEach(r => {
      res += r.RuleId + ':' + r.Access + '|';
    });
    this._selectedRules.unshift({ RuleId: -1, Access: -1 });
    if (res.length) {
      return res.substring(0, res.length - 1);
    }
  }

  private serializeRules() {
    const rules = this._guide.Rules.split('|');
    for (let i = 0; i < rules.length; i++) {
      const rule = rules[i].split(':');
      if (rule.length == 2 && rule[0] != '' && rule[1] != '') {
        this._selectedRules.push({ RuleId: rule[0], Access: rule[1] });
      }
    }
  }

  private loadRuleKeywords() {

    this.dataService.tokenRequest('/api/v1/system/rules/keywords', 'GET', {})
      .subscribe((res) => {
        if (res) {

          res.forEach((rule) => {
            this._ruleKeywords.push({ Id: rule.KeywordId, Name: rule.Keyword });
          });
        }

      });

  }

  private insertTextAtCursor(textArea: HTMLTextAreaElement, text: string, content: any): void {
    const start = textArea.selectionStart;
    const end = textArea.selectionEnd;
    const before = content.Body.substring(0, start);
    const after = content.Body.substring(end, content.Body.length);

    content.Body = before + text + after;
    // Update the cursor position
    setTimeout(() => {
      textArea.selectionStart = textArea.selectionEnd = start + text.length;
      textArea.focus();
    }, 0);
  }
}


