<swe-pageheader [header]="'Guider'" *ngIf="permissionService.permissions.GuideAccess>0"></swe-pageheader>
<div class="row py-2" *ngIf="permissionService.permissions.GuideAccess>0">
  <div class="col-12">
    <div class="row py-2">
      <div class="col-12 col-xl-5 float-start">
        <swe-guidedata [id]="id" [systemAdmin]="systemAdmin"></swe-guidedata>
      </div>
      <div class="col-12 col-xl-5 d-none d-sm-block" *ngIf="id > 0">
        <swe-guidepreview [id]="id" [isBaseGuide]="systemAdmin"></swe-guidepreview>
      </div>
    </div>
  </div>
</div>
