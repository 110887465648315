<div class="d-inline-block">
  <div *ngFor="let break of model; index as i">
    <swe-break [(model)]="break.Break" [(modelStart)]="break.BreakStart" [readonly]="readonly" [disabled]="disabled||isRedo" [disabledStart]="disabledStart||isRedo" [minStart]="minStart" [maxStart]="maxStart" [markChanges]="markChanges"></swe-break>
    <div class="d-inline-block ms-1" *ngIf="!disabled&&!readonly">
      <a class="swe-no-link" href="javascript:void(0);" [attr.title]="languageService.getItem(17)" (click)="remove(break)" *ngIf="reset||model.length>1"><i class="bi bi-swe-fw bi-dash-circle-fill"></i></a>
      <i class="bi bi-swe-fw" *ngIf="!(reset||model.length>1)"></i>
      <a class="swe-no-link ms-2" href="javascript:void(0);" [attr.title]="languageService.getItem(100)" (click)="add()" *ngIf="!onlyOne && model.length - 1 == i"><i class="bi bi-swe-fw bi-plus-circle-fill"></i></a>
    </div>
  </div>
</div>
<div class="d-inline-block" *ngIf="model.length==0&&!disabled&&!readonly">
  <span class="align-middle small me-3" *ngIf="reset">({{languageService.getItem(982)}})</span>
  <a class="swe-no-link" href="javascript:void(0);" [attr.title]="languageService.getItem(981)" (click)="redo()" *ngIf="reset"><i class="bi bi-swe-fw bi-arrow-clockwise" [ngClass]="{'bi-swe-spin': isRedo}"></i></a>
  <a class="swe-no-link ms-2" href="javascript:void(0);" [attr.title]="languageService.getItem(100)" (click)="add()"><i class="bi bi-swe-fw bi-plus-circle-fill"></i></a>
</div>
